@import "./../../../styles/global.scss";

/* General */
.kitbuilder {
  background: $background-color;
  .kitbuilder-main-wrapper {
    background: $background-color;
    min-height: 760px;
    flex-direction: column;
    display: flex;
   // min-width: $min-width-desk-;
    .kit-content {
      .plant-kit-subtitle {
        padding-top: 36px;
      }
    }
    .kit-irrigation {
      padding: 0 14%;
    }
  }

  .step-main-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    border-radius: 3px;
    margin-top: -64px;
    height: calc(100vh - 55px);

    &.step-cart {
      margin-top: 0px;

      .kit-room-layout {
        margin-top: 0px;
      }

      .step-content.kit-room-content {
        padding-top: 0;
      }
    }
    .kit-room-control .custom-button.secondary {
      width: calc(50% - 15px);
    }
    .kit-room-control .custom-button.primary-new {
      width: calc(50% + 1px);
      padding: 0px !important;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
  }

  .step-content {
    @include flex-all;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 80px;
    position: relative;

    &.kit-room-content {
      padding-top: 20px;
    }
  }
  .step-content .step-title {
    margin-bottom: $margin-bottom-title;
  }

  .step-content .custom-button {
    margin-top: $margin-top-button;
  }

  .step-title {
    text-align: center;
    margin: 0px;
    color: $title-color;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    font-family: $latto;
    text-transform: uppercase;
  }

  /* KitSelector */

  .kit-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .kit-container {
    p {
      text-align: center;
      margin: 0px;
      margin-block-start: 0px;
      margin-block-end: 0px;
      font-family: $helvetica-neue;
    }
  }

  /* Kit Room */
  .kit-room-content {
    width: 400px;
    border: 0.75px solid $light-grey;

    &.zone-wrapper {
      .zone-selection-square {
        width: 150px;
        height: 150px;
        background: #fff;
        border: 2px solid #fff;

        box-shadow: 5px 3px 5px rgba(0, 0, 0, 0.1);
        &.selected {
          border-color: #527434;
        }
        @include flex-all;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        margin-bottom: 25px;

        font-family: $latto;
      }
      .zone-label {
        text-transform: uppercase;
        text-align: center;
        font-size: 18px;
        line-height: 19px;
        font-weight: 700;
        padding-bottom: 7px;
        width: 65%;
        margin: 0 auto 20px;
        span {
          font-family: $latto;
          display: block;
          margin-top: 20px;
          line-height: 19px;
          font-weight: 500;
          color: #717376;
          font-size: 16px;
        }
      }
    }

    .custom-input {
      margin-bottom: 10px;
    }

    .custom-switch-toggle {
      margin-bottom: 43px;
    }

    .room-form {
      font-family: $latto;
      .room-form-label {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
        margin-top: 24px;
        &:first-child {
          margin-top: 0;
        }
      }
      .input-group {
        margin-top: 0;
      }
    }
    .step-title {
      color: #000;
      margin-bottom: 15px;
      width: 75%;
    }
    .step-subtitle {
      text-align: center;
      margin: 0px;
      color: #000;
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      font-family: "Lato";
      text-transform: uppercase;
    }

    p {
      line-height: 19px;
      text-transform: uppercase;
      color: #717376;
      width: 100%;
      margin: 10px auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .arrow-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 60%;
      .arrow-button {
        padding: 6px;
        width: 50%;
        text-align: center;

        &:hover {
          cursor: pointer;
        }

        &.disabled {
          opacity: 0.1;

          &:hover {
            cursor: default;
          }
        }
      }
    }

    .divider {
      width: 90%;
      margin: 10px auto;
      height: 1px;
      background: $very-light-grey;
    }
    .step-row {
      display: flex;
      justify-content: space-between;
      width: 90%;
      align-items: center;
      padding: 10px 0;
      z-index: 1;
      &__disabled {
        opacity: 0.5;
      }
      .description-tsi {
        text-transform: uppercase;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        line-height: 19px;
        font-weight: 700;
        padding-bottom: 7px;
        width: 45%;
        span {
          line-height: 19px;
          font-weight: 500;
          color: #717376;
        }
      }
      .input-dimension-tsi {
        width: 50px;
        height: 47px;
        /*margin: 0 auto;*/
        &.has-controls {
          width: 157px;
          @include flex-all;
          input {
            width: 50px;
            border-color: $very-light-grey;
            border-radius: 0px;
          }
          div,
          button {
            border: 0 none;
            appearance: none;
            -webkit-appearance: none;
            width: 43px;
            background-color: $very-light-grey;
            text-align: center;
            font-size: 23px;
            height: 100%;
            line-height: 46px;
            cursor: pointer;

            &.disabled {
              cursor: default;
              opacity: 0.2;
            }
          }
        }
        input {
          width: 100%;
          height: 100%;
          outline: none;
          border-radius: 0px;
          border: 1px solid #000;
          text-align: center;
          font-size: 24px;
          color: #000000;
          &.width-tray {
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }

  .info-label {
    text-align: center;
    font-size: 13px;
    line-height: 22px;
    margin-top: 70px;
  }
  .info-label-grow-area {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    margin-top: 0;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kit-room-control {
    @include flex-between;
    width: 100%;
    padding: 20px 14px;
    position: absolute;
    bottom: 0;
    .custom-button {
      width: calc(50% - 7px);
      margin: 0px;
      box-shadow: 5px 3px 5px rgba(0, 0, 0, 0.3);
    }
    background-color: white;
    padding-top: 11px;
  }

  .step-subtitle {
    color: $green-primary;
    font-size: 24px;
    font-weight: normal;
    line-height: 48px;
    margin: 0px;
    font-family: $helvetica-neue;
  }

  .kit-room-layout {
    padding-top: 0px;
    position: relative;
    margin-top: 64px;
    width: calc(100% - 395px);
    overflow: hidden;
    /* background:
    repeating-linear-gradient(to right,
        transparent 0  calc(46px - 2px),
        #e5e5e5 calc(46px - 2px) 46px),

    repeating-linear-gradient(to bottom,
        transparent 0 calc(46px - 2px),
        #e5e5e5 calc(46px - 2px) 46px)
    white; */
    .position-relative {
      position: static;
    }
    .kit-layout-visual {
      min-width: 800px;
      min-height: 500px;
      border: 6px solid #54565a;
      background: rgba(167, 168, 169, 0.2);
      position: absolute;
      top: 90px;
      left: 229px;

      .layout-visual-width {
        text-align: center;
        font-weight: $semibold;
        position: absolute;
        top: -60px;
        border-bottom: 3px solid #a7a8a9;
        width: 101%;
        line-height: 0.1px;
        margin-left: -6px;

        span {
          background: $white;
          padding: 0px 15px;
        }
      }

      .layout-visual-length {
        @include flex-all;
        text-align: center;
        font-weight: $semibold;
        position: absolute;
        right: -60px;
        height: 102%;
        border-left: 3px solid #a7a8a9;
        margin-top: -6px;

        span {
          background: $white;
          margin-left: -20px;
        }
      }
    }

    .kit-layout-control {
      #tooltip-control-question-button {
        position: relative;
        .abs-tooltip-image {
          position: absolute;
          bottom: 63px;
          left: 0px;
          box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.16);
        }
      }
      a {
        text-decoration: none;
        outline: none;
      }
      @include flex-all;
      padding: 20px 20px 25px;
      .custom-button {
        margin: 0px;
        margin-right: 14px;
      }
    }

    .kit-layout-logo {
      padding: 20px 20px 25px;
      img {
        height: 50px;
        display: block;
      }
    }
  }

  /* Kit Irrigation */
  .irrigation-kit-container {
    @include flex-all;
    flex-wrap: wrap;
    max-width: 1300px;
    font-family: $helvetica-neue;
    .irrigation-kit {
      @include flex-all;
      background: $green-primary;
      color: white;
      width: 323px;
      height: 150px;
      margin: 0 12.5px;
      margin-bottom: 25px;
      cursor: pointer;
      border-radius: 3px;
      p {
        padding: 45px;
        font-size: 24px;
        color: white;
        text-align: center;
      }
    }
  }

  .kit-room-import {
    .custom-button {
      margin-top: 0;
    }
  }
  .kit-option-input {
    justify-content: center;
  }

  /* GROW AREA */
  .inside-left-content {
    margin-bottom: 90px;
    overflow-x: hidden;
    overflow-y: auto;
    width: calc(100% - 10px);

    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 27px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      outline: none;
      border-radius: 27px;
    }
    &::-webkit-scrollbar {
      width: 12px;
    }
    .info-label-spaced {
      margin-bottom: 20px;
    }
  }
  .info-label-spaced {
    padding: 0 45px;
    font-family: $latto;
  }

  .item-tray-type {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }

    &.selected {
      img {
        border: 2px solid #487629;
        padding: 0px;
      }
    }
    img {
      width: 180px;
      height: 180px;
      background-color: #fff;
      object-fit: contain;
      padding: 2px;
    }
    .title-tray-type {
      font-size: 18px;
      color: #000;
      font-weight: 700;
      margin-top: 13px;
      .description-tray-type {
        font-weight: 500;
        font-size: 16px;
        display: block;
        color: #54565a;
      }
    }
    &:last-child {
      margin-bottom: 20px;
    }
  }
  #grow-area .step-title {
    color: #000;
    margin-bottom: 45px;
  }
  /* #GROW AREA */
  /* TRAY SIZE */
  .inside-left-content {
    .step-title {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
  .trays-selected {
    width: 100%;
    padding-left: 10px;
    font-family: $latto;
    .tray-selected-item[index="0"] {
      .tray-item-buttons {
        display: none;
      }
    }
    .tray-selected-item[active="1"] {
      .tray-selected-item-content-simple {
        display: none;
      }
      .tray-selected-item-content {
        display: block;
      }
      .tray-item-buttons {
        align-items: unset;
        top: 10px;
        .show-hide img {
          transform: rotate(180deg);
        }
      }
    }
    .tray-selected-item {
      &:not(:first-child) {
        padding-top: 26px;
      }
      padding-bottom: 26px;
      border-bottom: 0.75px solid #666666;
      position: relative;
      .tray-selected-item-content {
        display: none;
      }
      .tray-selected-item-content-simple {
        .simple-dimensions {
          width: 100%;
          text-align: center;
          font-size: 24px;
          color: #000;
          padding-top: 15px;
        }
      }
      .tray-item-buttons {
        position: absolute;
        top: 0px;
        right: 5px;
        display: flex;
        align-items: center;
        height: 100%;
        > div {
          cursor: pointer;
          &.show-hide {
            width: 32px;
            margin-right: 16px;
          }
          > img {
            width: 100%;
            height: auto;
          }
        }
      }
      .top-tsi {
        text-align: center;
        .name {
          font-size: 22px;
          font-weight: 700;
          line-height: 27px;
        }
      }
      .dimensions-tsi {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .dimension-tsi {
          margin-top: 5px;
          text-align: center;
          &.has-controls {
            margin-left: 10px;
          }
          .description-tsi {
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 7px;
          }
          span {
            display: block;
            font-size: 16px;
            line-height: 19px;
            font-weight: 500;
            color: #717376;
            margin-top: 10px;
          }
          .input-dimension-tsi {
            width: 101px;
            height: 47px;
            margin: 0 auto;
            &.has-controls {
              width: 187px;
              @include flex-all;
              input {
                width: 101px;
                border-color: $very-light-grey;
                border-radius: 0px;
              }
              div {
                width: 43px;
                background-color: $very-light-grey;
                text-align: center;
                @include flex-all;
                font-size: 23px;
                height: 100%;
                cursor: pointer;
                &.reduce-length {
                  padding-right: 4px;
                }
                &.raise-length {
                  transform: rotate(180deg);
                }
              }
            }
            input {
              width: 100%;
              height: 100%;
              outline: none;
              border-radius: 0px;
              border: 1px solid #000;
              text-align: center;
              font-size: 24px;
              color: #000000;
              &.width-tray {
                border: none;
                background-color: transparent;
              }
            }
          }
        }
      }
      .duplicate {
        font-size: 18px;
        text-align: center;
        color: #000;
        font-weight: 400;
        @include flex-all;
        cursor: pointer;
        margin-top: 45px;
        span {
          padding-left: 12px;
        }
      }
    }
  }
  .additional-tray {
    margin-top: 70px;
    text-align: center;
    padding-bottom: 120px;
    button.custom-button.add {
      margin: 0px auto;
      width: 81px;
      height: 77px;
      @include flex-all;
      font-size: 20px;
      background-color: #e6e6e6;
      border: 1px solid #b3b3b3;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000;
        margin: auto;
        width: 3px;
        height: 29.43px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000;
        margin: auto;
        height: 3px;
        width: 29.43px;
      }
    }
    .step-title {
      width: 100%;
    }
  }
  /* #TRAY SIZE */

  /* -- Container / Medium */

  .kit-room-container-medium {
    padding-top: 20px;
  }

  .container-or-medium-wrapper {
    overflow-y: auto;
    @include flex-all;
    flex-wrap: wrap;
    margin-bottom: 94px;
    width: calc(100% - 10px);
    justify-content: space-evenly;
    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 27px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      outline: none;
      border-radius: 27px;
    }
    &::-webkit-scrollbar {
      width: 12px;
    }
    .container-element {
      width: 136px;

      flex-wrap: wrap;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      cursor: pointer;

      img {
        border: 1px solid $white;
        height: 136px;
      }

      .container-label {
        max-width: 141px;
        margin: 0 auto;
        margin-top: 13px;
        font-size: 18px;
        line-height: 22px;
        font-family: $latto;
        user-select: none;
        font-weight: bold;
      }

      &.container-selected {
        img {
          border: 1px solid $green-primary;
          box-shadow: 0px 0px 5px $light-grey;
        }
      }
    }
  }

  /* -- Container / Medium */

  /* -- Recommended Builds */

  .kit-recommended-builds {
    padding-top: 30px;
    .step-title {
      margin-bottom: 20px;
    }
    .kit-recommended-builds-wrapper {
      overflow-y: auto;
      width: 100%;
      margin-bottom: 96px;
      padding-left: 15px;
      padding-right: 15px;
      flex: 1;
      @include container-scroll-type-scrollbar;
      .recommended-builds-subtitle {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 15px;
        font-weight: bold;
      }

      .list-column {
        @include flex-between;
        align-items: flex-start;
        &:not(:last-child) {
          border-bottom: 0.75px solid $light-grey;
        }
        margin-bottom: 15px;

        .list-element {
          cursor: pointer;
          width: 136px;
          margin-bottom: 20px;
          img {
            max-width: 100%;
            width: 136px;
            border: 1px solid $white;
          }

          .list-element-label {
            margin-top: 5px;
            text-align: center;
            max-width: 130px;
            font-weight: $semibold;
          }

          &.selected {
            img {
              border: 0.75px solid $green-primary;
              box-shadow: 0px 0px 5px $light-grey;
            }
          }
        }
        &.plants-area,
        &.dripper-style-trays {
          flex-wrap: wrap;
          justify-content: space-evenly;
        }
        &.pipe-diameter {
          justify-content: center;
          justify-content: space-evenly;
          padding-bottom: 20px;
          .custom-switch-toggle {
            margin-bottom: 0;
          }
        }
        &.plumbing-trays {
          justify-content: space-evenly;
        }
        &:last-child {
          margin: 0;
          padding: 0;
          padding-bottom: 20px;
        }
      }
    }
  }

  /* -- Recommended Builds */
  /* IRRIGATION STYLE */
  .listing-elements {
    padding-left: 30px;
    padding-right: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 90px;
    .list-element {
      margin-bottom: 30px;
      text-align: center;
      &.list-element-selected {
        img {
          border: 2px solid #487629;
          padding: 2px;
        }
      }
      .list-element-image {
        padding: 4px;
        max-width: 180px;
        max-height: 122px;
        background-color: #fff;
        object-fit: contain;
      }
      .list-element-label {
        font-family: $latto;
        font-size: 20px;
        line-height: 24px;
        color: #000;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
  /* #IRRIGATION STYLE */

  /* Cart summary */

  .kit-cart-summary {
    padding-top: 0px;
    .room-form {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: 100%;
      div {
        margin-bottom: 0px;
      }
      .cart-title {
        background: $green-primary;
        margin: 0px;
        padding: 16px 0px;
        text-align: center;
        h1 {
          color: $white;
          font-family: $latto;
          font-size: 19px;
          line-height: 21px;
          margin: 0px;
        }
      }

      .cart-elements {
        height: calc(100vh - 213px);
        overflow-y: auto;
        margin-bottom: 0px;

        &::-webkit-scrollbar-track {
          background: #fff;
          border-radius: 27px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ccc;
          outline: none;
          border-radius: 27px;
        }
        &::-webkit-scrollbar {
          width: 12px;
        }

        .element {
          padding-top: 20px;
          padding-bottom: 15px;
          margin-left: 20px;
          margin-right: 15px;
          border-bottom: 0.75px solid $light-grey;
          &:last-child {
            border-bottom: none;
          }
          @include flex-between;

          &.build-components {
            .element-info {
              div {
                margin-bottom: 0px;
                font-size: 16px;
                line-height: 19px;
                font-family: $latto;
                text-transform: uppercase;
              }
            }
          }

          &.nutrients {
            .element-info {
              .input-group {
                margin-top: 5px;
                > button {
                  width: 47px;
                  height: 47px;
                  padding: 0px;
                  @include flex-all;
                }
              }

              input.custom-input {
                min-width: unset;
                max-width: 60px;
                margin-bottom: 0px;
                height: 47px;
              }
            }
          }

          &.recommended-parts {
            padding-bottom: 0px;
            text-transform: uppercase;
            .element-content .content-info .text .element-info {
              margin-bottom: 20px;
            }
            .element-content {
              .content-info {
                .element-info {
                  .third-p-item {
                    margin-bottom: 20px;
                    &:last-child {
                      margin-bottom: 0px;
                    }
                    .title-third-p-item {
                      font-size: 16px;
                      line-height: 19.2px;
                      font-weight: 700;
                      color: #487629;
                      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    }
                    > span {
                      font-size: 16px;
                      line-height: 19.2px;
                      font-weight: 500;
                      color: #54565a;
                      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    }
                  }
                }
              }
            }
          }

          .image-element {
            margin: 0px;
            text-align: center;
            min-width: 136px;
            max-width: 136px;
          }

          .element-content {
            margin: 0px;
            padding-right: 10px;
            width: 100%;

            .content-info {
              @include flex-between;
              margin-bottom: 0px;

              .text {
                margin-bottom: 0px;
                padding-right: 10px;

                .title {
                  font-weight: bold;
                  color: black;
                  font-family: $latto;
                  font-size: 19px;
                  line-height: 22px;
                  margin-bottom: 2px;
                }

                .element-info {
                  color: #54565a;
                  font-size: 16px;
                  line-height: 19px;
                  margin-bottom: 26px;
                  // white-space: nowrap;

                  span {
                    color: $green-primary;
                    font-weight: $semibold;
                  }
                }
              }
            }

            .content-price {
              @include flex-between;

              .price {
                margin: 0px;
                color: $green-primary;
                font-size: 24px;
                line-height: 30px;
                font-family: $latto;
                font-weight: bold;
              }
            }
          }

          .c-pointer {
            cursor: pointer;
          }
        }
      }
    }

    .cart-control {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      .cart-control-border-top {
        padding-left: 5px;
        width: 100%;
        div {
          border-top: 0.75px solid $light-grey;
          height: 20px;
          width: 100%;
        }
      }

      .cart-value {
        text-align: center;

        .plant-price {
          font-size: 19px;
          font-family: $latto;
          line-height: 24px;

          span {
            color: $green-primary;
            font-weight: $semibold;
          }
        }

        .cart-subtotal {
          font-size: 21px;
          font-family: $latto;
          line-height: 26px;
          font-weight: $semibold;
          color: $green-primary;
        }
      }

      .kit-room-control {
        padding-left: 0;
        padding-right: 0;
        position: relative;
        width: calc(100% + 4px);
      }
      .kit-room-edit-btn {
        &.secondary {
          width: unset;
          min-width: 190px;
          padding-left: 5px;
          padding-right: 5px;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
        }
        display: flex;
        align-items: center;
        width: auto;
        &::before {
          content: url(./../../../assets/svg/edit-wheel.svg);
          position: relative;
          left: 0;
          margin-right: 7px;
          filter: drop-shadow(4px 3px 2px rgba(0, 0, 0, 0.16));
        }
      }

      .primary-new {
        width: auto !important;
        &.checkout-cart-btn {
          flex: 1;
        }
      }
    }
  }

  .kit-cart-layout {
    .canvas-3d-rm- {
      background-color: #f2f2f2;
      z-index: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      #3D_Canvas {
      }
      .absolute-img-placeholder-temp {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: white;
        img {
        }
      }
    }
    .canvas-3d-r-c-switch {
      padding: 40px;
    }
    .kit-cart-layout-content {
      height: calc(100vh - 55px);
      padding-top: 64px;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      width: 100%;
      .tmp-image {
        text-align: center;
        > img {
          margin-top: 25px;
          width: auto;
          height: calc(100vh - 322px);
        }
      }
      .custom-switch-toggle {
        width: 174px;
        margin-left: auto;
        margin-right: auto;
      }
      .layout-control {
        &:not(.canvas-3d-layout-control) {
          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 0;
          background: white;
          z-index: 2;
          left: 100%;
          top: 0;
          width: calc(100vw - 395px);
          height: 100%;
          padding: 20px;
          // This changes the layout a bit but is needed to make the water consumption value sticky
          //min-width: 1500px;
          .close-popup-wrapper {
            width: 100%;
            text-align: right;
            bottom: 0;

            .kit-layout-control-img {
              bottom: 100%;
            }
          }
          &.img-2-img {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0;
            background: transparent;
            z-index: 999;
            left: 0;
            bottom: 0;
            height: auto;
            padding: 20px;
          }
        }

        &.canvas-3d-layout-control {
          display: flex;
          justify-content: space-between;
          position: absolute;
          z-index: 2;
          left: 0;
          bottom: 0;
          width: calc(100vw - 395px);
          height: auto;
          padding: 0;
          .close-popup-wrapper {
            width: 100%;
            text-align: right;
            bottom: 0;

            .kit-layout-control-img {
              bottom: 100%;
            }
          }
          &.img-2-img {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0;
            background: transparent;
            z-index: 999;
            left: 0;
            bottom: 0;
            height: auto;
            padding: 20px;
          }
        }
      }
    }
    .absolute-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f2f2f2;
      z-index: 0;
      background-image: url("../../../assets/image/cartSummaryBg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .konvajs-content {
    background-color: white;
  }

  .cart-popup {
    position: absolute;
    background: white;
    z-index: 2;
    left: 100%;
    top: 0;
    width: calc(100vw - 395px);
    height: 100%;
    padding: 20px;
    min-width: 1500px;

    &[page="calculator"] {
      min-width: auto;
    }

    .close-popup-wrapper {
      width: 100%;
      text-align: right;
    }
    &.bc-popup {
      .close-popup-wrapper {
        position: fixed;
        right: 27px;
        top: 83px;
        cursor: pointer;
        z-index: 9;
      }
    }
    /* Build popup */

    .build-components-popup,
    .recommended-3rd-party-parts-popup {
      height: 100%;
      width: 100%;
      position: relative;

      .build-logo-wrapper {
        @include flex-all;
        border-bottom: 0.75px solid $light-grey;
        width: 100%;
        padding-bottom: 18px;

        h1 {
          font-size: 25px;
          line-height: 25px;
          font-family: $latto;
          color: $green-primary;
          margin-left: 14px;
        }
      }

      .build-description {
        text-align: center;
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        font-family: $latto;
        margin-top: 21px;
      }

      .components-wrapper {
        display: flex;
        align-items: stretch;
        margin-top: 45px;
        height: 70%;

        .component {
          width: 33.33%;
          padding: 15px;

          &:not(:last-child) {
            border-right: 0.75px solid $light-grey;
          }

          .component-title {
            @include flex-all;
            margin-top: -35px;

            img {
              max-height: 45px;
            }

            .text {
              font-size: 20px;
              font-weight: bold;
              line-height: 25px;
              font-family: $latto;
              margin-left: 6px;
            }
          }

          .components-list {
            width: 100%;
            margin-top: 22px;
            a {
              text-decoration: none;
              color: inherit;
            }
          }

          .component-line {
            text-transform: uppercase;
            @include flex-between;
            background: #f2f2f2;

            padding: 10px 10px 8px 10px;

            &.bbottom {
              border-bottom: 0.75px solid #e6e6e6;
            }

            .flex {
              @include flex-all;

              &.flex-start {
                @include flex-start;
              }

              img {
                width: 53px;
                height: 53px;
                object-fit: contain;
              }

              .label {
                font-size: 16px;
                line-height: 19px;
                font-family: $latto;
                text-align: left;
                margin-left: 12px;
              }

              .quantity-b-line-n-c-rm {
                height: 48px;
                width: 61px;
                background: $white;
                border: 0.75px solid $light-grey;
                font-size: 24px;
                line-height: 28px;
                font-family: $latto;
                width: 61px;
                text-align: center;
                margin-right: 14px;
              }
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
              }

              input[type="number"] {
                -moz-appearance: textfield; /* Firefox */
              }

              .price {
                font-size: 16px;
                line-height: 19px;
                font-family: $latto;

                span {
                  font-size: 24px;
                  line-height: 25px;
                  font-family: $latto;
                  font-weight: bold;
                  color: $green-primary;
                }
              }
            }
          }
        }
      }

      .components-price {
        position: fixed;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 20px;

        @include flex-all;

        .price-title {
          text-align: center;
          font-size: 20px;
          line-height: 24px;
          font-family: $latto;

          span {
            color: $green-primary;
            font-weight: bold;
          }

          &.per-plant {
            margin-right: 20px;
          }
        }
      }
    }

    .recommended-3rd-party-parts-popup {
      .components-wrapper {
        justify-content: center;
        .components-list {
          width: 100%;
          a {
            width: 100%;
            display: block;
            .component-line {
              width: 100%;

                & > div:first-child {
                  overflow: hidden;
                  width: 100%;
                  .main-comp-info {
                    flex: 1;
                    overflow: hidden;
                    p {
                      width: 100%;
                    }
                  }
                }

            }
          }
          .component-line {
            width: 100%;
            .comp-label {
              overflow: hidden;
              text-overflow: ellipsis;
              flex: 1;
            }
          }
          .main-comp {
            background: #f2f2f2;
            padding: 10px 10px 8px 10px;
            min-height: 65px;

            img {
              max-height: 30px;
              max-width: 30px;
            }

            .main-comp-info {
              margin-left: 14px;

              p {
                margin: 0px;
                margin-inline-start: 0px;
                margin-inline-end: 0px;

                &.text {
                  font-size: 18px;
                  line-height: 22px;
                  font-family: $latto;
                  font-weight: bold;
                }

                &.subtext {
                  font-size: 16px;
                  line-height: 19px;
                  font-family: $latto;
                }
              }
            }

            .main-comp-value {
              font-size: 24px;
              line-height: 28px;
              font-weight: bold;
              font-family: $latto;
              min-width: 30px;
              text-align: center;
            }
          }

          .desc-comp {
            .comp-label {
              font-size: 16px;
              line-height: 19px;
              font-family: $latto;
            }

            .comp-value {
              font-size: 24px;
              line-height: 28px;
              font-family: $latto;
              font-weight: bold;
              color: $green-primary;
              min-width: 30px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .blur-item-rm-left {
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  }
  /* Cart summary */

  #step-content-3d-rm {
    &[load3dhidden="true"] {
      position: relative;
      left: 100vw;
      width: 0;
      overflow: hidden;
      .kit-cart-layout-content {
        position: absolute;
        left: 100vw;
        width: 0;
        overflow: hidden;
      }
    }
  }
}

.box-pk-item.plants {
}

.container-label-custom-pot {
  max-width: 230px;
  margin: 13px auto 20px;
  font-size: 18px;
  line-height: 22px;
  font-family: "Lato", sans-serif;
  -webkit-user-select: none;
  user-select: none;
  font-weight: bold;
  text-transform: uppercase;
}

.platform-controls {

}
.kitbuilder {
  .kit-room-layout {
    .kit-layout-control {
      .custom-button {
       &.control-3d-share-btn {
         img {
           margin-top: 6px;
           margin-right: 3px;

         }
        }
      }
    }
  }
}