@import "./../../../styles/global.scss";

.purchase-kits-simple-page {
  font-family: $latto;
  width: 100%;
  padding-bottom: 124px;
  background-color: #fff;
  .header-purchase-kits {
    @include flex-all;
    justify-content: space-between;
    width: 100%;
    background-color: #f1f1f1;
    border: 1px solid $grey-666;
    .header-button-group {
      @include flex-all;
      width: 395px;
      .header-button {
        width: 50%;
      }
      &.singular {
        width: calc(395px / 2);
        .header-button {
          width: 100%;
        }
      }
    }
    .header-button {
      text-align: center;
      color: $green-primary;
      font-size: 20px;
      line-height: 24px;
      border-right: 0.75px solid $grey-666;
      padding-top: 15px;
      padding-bottom: 14px;
      &:last-child {
        cursor: pointer;
      }
    }
    .header-base-url {
      text-align: center;
      color: $green-primary;
      font-size: 20px;
      line-height: 24px;
    }
    .header-banner {
      width: auto;
      flex-grow: 1;
      img {
        display: block;
        max-height: 55px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  &.extra-kits {
    .simple-page-content {
      .title-pk {
        max-width: 1060px;
        text-align: center;
        margin: 0 auto;
        right: 0px;
      }
      .box-b-pk-item {
        width: 443px;
        background-color: #f1f1f1;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        &:last-child {
          margin-left: 30px;
        }
        .content-box-b-pk {
          padding: 18px 24px 48px;
          .image-content-box-b-pk {
            max-width: 100%;
            height: auto;
            background-color: #fff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            img {
              max-width: 100%;
              height: auto;
            }
          }
          .label-box-b-pk {
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            color: #000;
            text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            max-width: 240px;
            margin: 17px auto 0px;
            text-align: center;
          }
          .button-box-b-pk {
            text-align: center;
            margin-top: 22px;
            button {
              background-color: #00abc8;
              cursor: pointer;
              padding: 0px;
              text-align: center;
              border: none;
              box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
              outline: none;
              color: #fff;
              font-size: 21px;
              font-weight: 700;
              min-width: 203px;
              padding: 0 20px;
              height: 63px;
              line-height: 63px;
            }
          }
        }
      }
      .content-pk {
        .container-pk-items {
          .boxes-pk-items {
            min-height: 300px;
          }
        }
      }
      .add-button-box-b-pk {
        &.product-added-box {
          &::after {
            content: url(./../../../assets/svg/check.svg);
            width: 5.79px;
            height: 18.67px;
            margin-left: 20px;
            position: relative;
            top: 4px;
          }
        }
      }
    }
  }
  .simple-page-content {
    .top-purchase-kits {
      width: 100%;
      position: relative;
      .img-top-pk {
        width: 100%;
        max-height: 258px;
        img {
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .title-pk {
        font-size: 36px;
        line-height: 44px;
        font-weight: 700;
        text-transform: uppercase;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        position: absolute;
        top: 0;
        left: 0;
        color: #000;
        @include flex-all;
        width: 100%;
        height: 100%;
        sup {
          top: -3px;
          font-size: medium;
          vertical-align: super;
        }
      }
    }
    .content-pk {
      width: 100%;
      .container-pk-items {
        width: 100%;
        .boxes-pk-items {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
          padding-top: 38px;
          .box-pk-item {
            background-color: #f1f1f1;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            &.plants {
              width: 624px;
              margin-right: 28px;
              min-height: 652px;
              .content-box-pk {
                padding: 0px 40px 0px;
                min-height: 504px;
                display: flex;
                flex-wrap: wrap;
                .item-content-box-pk {
                  font-size: 40px;
                  @include flex-all;
                  font-weight: 700;
                  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                  color: #a7a8a9;
                  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                  background-color: #fff;
                  width: 202px;
                  height: 202px;
                  margin-left: auto;
                  margin-right: auto;
                  margin-bottom: 50px;
                  cursor: pointer;

                  &.selected {
                    border: 2px solid #487629;
                    color: #487629;
                  }
                }
              }
            }
            &.pots {
              width: 579px;
              .content-box-pk {
                display: flex;
                flex-wrap: wrap;
                padding: 0px 30px 8px;
                min-height: 504px;
                .item-content-box-pk {
                  text-align: center;
                  margin-bottom: 27px;
                  cursor: pointer;

                  .img-content-box-pk {
                    width: 203px;
                    height: 203px;
                    background-color: #fff;
                    padding: 2px;
                    img {
                      width: 100%;
                      height: auto;
                      max-height: 203px;
                      object-position: center;
                      object-fit: cover;
                    }
                  }
                  .label-item-content-box-pk {
                    font-size: 18px;
                    line-height: 22px;
                    color: #000;
                    text-align: center;
                    font-weight: 700;
                    padding-top: 6px;
                    width: 203px;
                  }
                  &.selected {
                    .img-content-box-pk {
                      border: 2px solid #487629;
                      padding: 0px;
                    }
                  }
                }
              }
            }
            .title-box-pk {
              font-size: 25px;
              line-height: 30px;
              font-weight: 700;
              color: #000;
              text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
              text-align: center;
              padding-top: 26px;
            }
            .content-box-pk {
              margin-top: 28px;
              .row-content-box-pk {
                display: flex;
                justify-content: center;
                .item-content-box-pk {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
  .pk-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 124px;
    padding-right: 16px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding-top: 30px;
    .pk-button {
      width: 175px;
      height: 70px;
      text-transform: uppercase;
      text-align: center;
      font-size: 21px;
      font-weight: 700;
      color: #fff;
      background-color: #61a60e;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border: none;
      outline: none;
      padding: 0px;
      cursor: pointer;
      &.back {
        margin-right: 14px;
        color: #487629;
        background-color: #fff;
      }
    }
  }
}
