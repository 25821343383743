@import "./../../../../../../styles/global.scss";
.react-app-room-builder-nutrient-calculator-container {
  .counter-slider {
    width: calc(50% - 75px);
    max-width: 100vw;
    .slider-label {
      color: black;
      font-family: $Akzidenz;
      text-align: left;
      width: 100%;
      font-weight: normal;
      font-size: 22px;
      line-height: 25px;
      padding-right: 20px;
    }
    .slider-wrapper {
      background-color: #f2f2f2;
      min-height: 180px;
      grid-template-rows: 1fr 1fr;
      input:not([disabled]):focus {
        box-shadow: 0 0 3px 1px $light-grey !important;
      }
    }
    .test-wrapper {
      margin-top: 0;
      margin-bottom: 0;
    }
    .row {
      margin-bottom: 30px;
    }
    &.min-width {
      width: unset;
      min-width: 460px;
    }
    &.height-var-1 {
      width: auto;
      min-width: 590px;
      @media (max-width: 600px) {
        min-width: 100%;
      }
      .slider-wrapper {
        min-height: 180px;
      }
      .slider-label {
        color: black;
        font-weight: normal;
        padding-right: unset;
      }
    }
    @media (max-width: 800px) {
      width: calc(100% - 20px);
    }
  }
}
