@import "./../../../../styles/global.scss";

.header {
  .header-content {
    $my-height: $header-height;
    width: 100%;
    display: flex;
    height: $my-height;
    background-color: $green-primary;

    .back-button {
      @include flex-all;
      width: $my-height;
      height: $my-height;
      cursor: pointer;
      svg {
        width: 26px;
        height: 26px;
        path {
          stroke: $white;
          stroke-width: 3px;
        }
      }
    }
    .header-logo {
      display: flex;
      align-items: center;
    }
    .header-text {
      color: white;
      font-size: 16px;
      font-family: $latto;
      font-weight: bold;
    }
    .header-right {
      flex: 1;
    }
  }
}

.header-new {
  @include flex-all;
  justify-content: space-between;
  //min-width: $min-width-desk-;
  .header-button-group {
    @include flex-all;
    width: 395px;
    .header-button {
      width: 50%;
    }
  }

  .header-base-url {
    text-align: center;
    color: $green-primary;
    font-size: 20px;
    line-height: 24px;
  } 

  .header-button {
    text-align: center;
    color: $green-primary;
    font-size: 20px;
    line-height: 24px;
    border: 0.75px solid $grey-666;
    padding-top: 15px;
    padding-bottom: 14px;
    position: relative;
    font-weight: 500;
    cursor: pointer;
    font-family: $latto;
    &#header-save {
      > span {
        display: inline-block;
        width: 100%;
        > span {
          display: block;
          width: 44px;
          height: 55px;
          position: absolute;
          top: 0px;
          right: 0px;
          @include flex-all;
        }
      }
      &[active="1"] {
        > span > span {
          transform: rotate(180deg);
        }
      }
    }
  }

  .save-pop {
    @include flex-all;
    position: absolute;
    top: 55px;
    left: -1px;
    right: 0px;
    border: 1px solid #959595;
    border-top: none;
    height: 63px;
    background-color: #fff;
    top: 55px;
    z-index: 9;
    .item-save-pop {
      color: $green-primary;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .header-button.my-account {
    position: fixed;
    right: 0;
    top: 0;
    width: 210px;
    background: white;
  }

  .header-banner {
    width: auto;
    flex-grow: 1;
    img {
      display: block;
      max-height: 55px;
      width: 100%;
      object-fit: cover;
    }
  }
}
