@import "../../../styles/global.scss";
@-moz-keyframes rotateButton { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes rotateButton { 100% { -webkit-transform: rotate(360deg); } }
@keyframes rotateButton { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.dashboard-simple-page {
  .page-spinner {
    min-height: 100%;
  }
  font-family: $latto;
  .empty-dash-hist {
    width: 100%;
    height: calc(100vh - 455px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 25px;
    color: #000;
  }
  .header-dashboard {
    @include flex-all;
    justify-content: space-between;
    min-width: $min-width-desk-;
    background-color: #f1f1f1;
    border: 1px solid $grey-666;
    .header-button-group {
      @include flex-all;
      width: 395px;
      .header-button {
        width: 50%;
      }
    }
    .header-button {
      text-align: center;
      color: $green-primary;
      font-size: 20px;
      line-height: 24px;
      border-right: 0.75px solid $grey-666;
      padding-top: 15px;
      padding-bottom: 14px;
      &:last-child {
        cursor: pointer;
      }
    }
    .header-base-url {
      text-align: center;
      color: $green-primary;
      font-size: 20px;
      line-height: 24px;
    }
    .header-banner {
      width: auto;
      flex-grow: 1;
      img {
        display: block;
        max-height: 55px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .simple-page-content {
    .page-hero-top {
      width: 100%;
      height: 315px;
      @include flex-all;
      text-align: center;
      flex-direction: column;
      background-image: url("../../../assets/image/dashboardHero.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .page-hero-logo {
        width: auto;
        margin-bottom: 20px;
        img {
          height: auto;
          width: 100%;
          max-width: 612px;
        }
      }
      .page-hero-welcome {
        color: #000000;
        font-size: 36px;
        line-height: 44px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
  .dashboard-bar {
    width: 100%;
    @include flex-all;
    color: #fff;
    height: 85px;
    background-color: #487629;
    padding: 0 36px;
    font-size: 25px;
    font-weight: 700;
    z-index: 2;
    .item-dash-bar {
      &:first-child {
        width: calc(655px - 40px);
      }
      &[search="true"] {
        margin-left: auto;
        position: relative;
        span {
          display: flex;
          position: absolute;
          left: 10px;
          top: 0;
          align-items: center;
          height: 100%;
          img {
            width: 24.75px;
            height: 24.75px;
          }
        }
        input {
          font-size: 21px;
          color: #000;
          padding: 0px 10px 0px 45px;
          height: 55px;
          font-family: $latto;
          outline: none;
          border: none;
          border-radius: 0px;
          &::placeholder {
            color: inherit;
          }
        }
      }
    }
  }
  .dash-page-content {
    display: flex;
    width: 100%;
    flex-direction: row;
    .title-dash {
      font-size: 30px;
      color: #000;
      font-weight: 700;
      text-transform: uppercase;
      padding-top: 2px;
      position: relative;
      .room-original-creator {
        color: $green-primary-new;
        font-size: 18px;
        line-height: 22px;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 34px;
      }
    }
    .left-dash-page-content {
      width: 655px;
      border-right: 1px solid #707070;
      .new-build-container {
        width: 100%;
        height: 100%;
        text-align: center;
        @include flex-all;
        flex-direction: column;
        .new-build-btn {
          cursor: pointer;
          background-color: #e6e6e6;
          border: 1px solid #b3b3b3;
          width: 81px;
          height: 77px;
          position: relative;
          margin: 13px auto 0px;
          @include flex-all;
          &:after,
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            background-color: #000;
            content: "";
          }
          &:after {
            width: 29.43px;
            height: 3px;
          }
          &:before {
            height: 29.43px;
            width: 3px;
          }
        }
      }
    }
    .right-dash-page-content {
      width: calc(100% - 606px);
      height: calc(100vh - 455px);
      overflow-x: hidden;
      overflow-y: auto;
      margin-right: 6px;
      &::-webkit-scrollbar-track {
        background: #e3e3e3;
        border-radius: 27px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        outline: none;
        border-radius: 27px;
      }
      &::-webkit-scrollbar {
        width: 12px;
      }
      .build-history-items {
        width: 100%;
        .build-h-item {
          display: flex;
          flex-direction: row;
          width: 100%;
          
          margin-bottom: 20px;
          padding-left: 8px;
          .left-build-h {
            flex: 1;
            padding-left: 19px;
            background-color: #f1f1f1;

            .build-h-item-details {
              width: 100%;
              padding-top: 17px;
              .row-build-h {
                width: 100%;
                display: flex;
                flex-direction: row;
                .item-row-build-h {
                  width: 33.33%;
                  font-size: 20px;
                  font-weight: 700;
                  &:not(:last-child) {
                    margin-bottom: 20px;
                  }
                  .name-item-row-build {
                    color: #000;
                  }
                  .values-item-row-build {
                    color: #61a60e;
                    margin-top: -4px;
                    display: flex;
                    max-width: calc(100% - 10px);
                    align-items: center;
                    flex-direction: row;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-transform: uppercase;
                    &.-line-overflow-elipsis {
                      @include overflow-elipsis-style;
                    }
                    .counter-trays {
                      @include flex-all;
                      width: 24px;
                      height: 24px;
                      background-color: #61a60e;
                      border-radius: 50%;
                      margin-right: 4px;
                      color: #fff;
                      text-align: center;
                      padding-bottom: 2px;
                    }
                    .value-trays {
                      width: calc(100% - 30px);
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                  .image-item-row-build {
                    width: 83px;
                    height: 80px;
                    margin-left: 0;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      object-position: center;
                    }
                  }
                  &.last-row-build {
                    padding-left: 27px;
                  }
                }
              }
              .bottom-section-build-h {
                display: flex;
                align-items: center;
                width: 100%;
                height: 46px;
                flex-direction: row;
                button {
                  border: none;
                  border-radius: 0px;
                  outline: none;
                  height: 100%;
                  padding-left: 12px;
                  padding-right: 12px;
                  font-size: 21px;
                  color: #fff;
                  font-weight: 700;
                  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
                  margin-right: 20px;
                  position: relative;
                  &.reorder-btn {
                    background-color: #1babc8;
                    width: 250px;
                    text-align: center;
                    > span {
                        width: 36px;
                        height: 36px;
                        box-sizing: border-box;
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;
                        border-radius: 50%;
                        border: 5px solid;
                        border-left-color: transparent;
                        display: block;
                        margin: 0 auto;
                        -webkit-animation:rotateButton 1s linear infinite;
                        -moz-animation:rotateButton 1s linear infinite;
                        animation:rotateButton 1s linear infinite;
                    }
                  }
                  &.buy-room-btn {
                    background-color: #61a60e;
                  }
                }
                .date-build-h {
                  font-size: 16px;
                  color: #000;
                }
              }
            }
          }
          .right-build-h {
            width: calc(380px + 5px);
            padding-right: 5px;
            position: relative;
            background-color: #f1f1f1;
            
            .image-render-build-item {
              width: calc(100% - 6px);
              height: 380px;
              img {
                max-width: 100%;
                max-height: 100%;

              }
            }



            &:hover {
              .controls-img-rendered {
                opacity: 1;
              }
            }
            .controls-img-rendered {
              opacity: 0;
              position: absolute;
              right: 0;
              bottom: 0;
              height: 64px;
              background-color: #f1f1f1;
              display: flex;
              align-items: center;
              flex-direction: row;
              padding-left: 25px;
              padding-right: 15px;
              transition: all linear 0.2s;
              > span,
              > a {
                cursor: pointer;
                display: block;
                font-size: 21px;
                font-weight: 700;
                color: #000;
                text-decoration: none;
                &:not(:last-child) {
                  margin-right: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  .padding-left-26 {
    padding-left: 26px;
  }
}
