@import "./global.scss";

@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./../assets/fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./../assets/fonts/HelveticaNeueRegular.woff2") format("woff2"),
    url("./../assets/fonts/HelveticaNeueRegular.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  src: url("./../assets/fonts/FontAwesome5FreeRegular.woff2") format("woff2"),
    url("./../assets/fonts/FontAwesome5FreeRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue Bold";
  src: url("./../assets/fonts/HelveticaNeue-Bold.woff2") format("woff2"),
    url("./../assets/fonts/HelveticaNeue-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Compact Display";
  src: url("./../assets/fonts/SFCompactDisplay-Semibold.woff2") format("woff2"),
    url("./../assets/fonts/SFCompactDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../assets/fonts/Lato-Black.woff2") format("woff2"),
    url("./../assets/fonts/Lato-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../assets/fonts/Lato-Bold.woff2") format("woff2"),
    url("./../assets/fonts/Lato-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato Bold";
  src: url("./../assets/fonts/Lato-Bold.woff2") format("woff2"),
    url("./../assets/fonts/Lato-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../assets/fonts/Lato-BlackItalic.woff2") format("woff2"),
    url("./../assets/fonts/Lato-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("./../assets/fonts/Lato-HairlineItalic.woff2") format("woff2"),
    url("./../assets/fonts/Lato-HairlineItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("./../assets/fonts/Lato-Hairline.woff2") format("woff2"),
    url("./../assets/fonts/Lato-Hairline.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../assets/fonts/Lato-Italic.woff2") format("woff2"),
    url("./../assets/fonts/Lato-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../assets/fonts/Lato-LightItalic.woff2") format("woff2"),
    url("./../assets/fonts/Lato-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../assets/fonts/Lato-Light.woff2") format("woff2"),
    url("./../assets/fonts/Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../assets/fonts/Lato-BoldItalic.woff2") format("woff2"),
    url("./../assets/fonts/Lato-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../assets/fonts/Lato-Regular.woff2") format("woff2"),
    url("./../assets/fonts/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: $background-color;
}
* {
  box-sizing: border-box;
}

// utils
.text-center {
  text-align: center;
}
.flex-start {
  @include flex-start;
}
.flex-end {
  @include flex-end;
}
.flex-between {
  @include flex-between;
}
.flex-all {
  @include flex-all;
}
.flex-column {
  flex-direction: column;
}
.flex-evenly {
  justify-content: space-evenly;
}
.align-start {
  align-items: flex-start;
}
.flex-1 {
  flex: 1;
}
.p-right-50 {
  padding-right: 50px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.position-absolute,
.position-fixed {
  position: absolute;
  &.--left-pos {
    left: 0;
  }
  &.--right-pos {
    right: 0;
  }
  &.--bottom-pos {
    bottom: 0;
  }
  &.--top-pos {
    top: 0;
  }
}
.all-100 {
  width: 100%;
  height: 100%;
}
.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}
.top--100 {
  top: -100%;
}
.display-none {
  display: none;
}

.-line-overflow-elipsis {
  display: block; //overwrite styles like "display:flex" which prevent elipsis from working
  overflow: hidden;
  text-overflow: ellipsis;
}


.two-group-wrapper {
  & > div:first-child,
  & > button:first-child {
    margin-right: 227px;
  }
}
.flex-all {
  @include flex-all;
  width: 100%; //this is super bad, no reason, flex-all is just about flex, make another class for 100%
}
.width-auto {
  width: auto;
}
.select-none {
  user-select: none;
}
.absolute-bottom {
  position: absolute;
  bottom: 0;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}
.absolute-top {
  position: absolute;
  top: 0;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}
.generic-text-shadow {
  text-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}
.generic-box-shadow {
  @include generic-box-shadow();
}
.generic-drop-shadow {
  filter: drop-shadow(4px 3px 2px rgba(0, 0, 0, 0.16));
}
/**
  FORM
*/
.one-sized {
  @include one-sized;
}
.main-label {
  @include flex-start;
  font-family: $latto;
  font-size: 16px;
  line-height: 19px;
}
.sec-label {
  @include flex-start;
  font-family: $latto;
  font-size: 12px;
  line-height: 15px;
}
.input-group {
  @include flex-start;
  margin-top: 26px;
  .input-group-label {
    padding-right: 20px;
    color: $dark-grey;
    input {
      width: 144px;
    }
  }
}
.latto {
  font-family: $latto;
}
.white-space-nowrap {
  white-space: nowrap;
}

/*
 label and boxes
*/
.red-note {
  @include flex-all;
  background-color: $red-alert;
  width: 100%;
  padding-bottom: 14px;
  padding-top: 5px;
  margin-top: 12px;
  span {
    font-family: $latto;
    font-size: 16px;
    line-height: 14px;
    color: $white;
  }
}
//this goes outside body so can't be nested
#button-tooltip-rom-builder-nc {
  .tooltip-inner {
    max-width: 350px;
  }
}
#generic-tooltip-id {
  .tooltip-inner {
    max-width: 350px;
  }
}
.sk-fading-circle {
  display: none!important;
}

textarea {
  @include generic-box-shadow();
  border: 1px solid $highlight-dr-color;
  padding: 15px;
  outline-color: $green-stroke;
  /* Keyword values */
  outline-width: thin;

  /* <length> values */
  outline-width: 1px;
  color: $idle-grey;
  font-size: 24px;
  &::placeholder {
    opacity: 0.6;
  }
}
.page-spinner {
  
}

#canvas-for-screenshot {
  position: fixed;
  left: 10px;
  top: 10px;

}

.generic-text-list {
    font-size: 18px;
    line-height: 22px;
    color: inherit;
    text-align: left;
    font-weight: 700;
}

/* General */
.tooltip-control-question-button {
  position: relative;
  .abs-tooltip-image {
    position: absolute;
    bottom: 63px;
    left: 0px;
    box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.16);
  }
}



.kitbuilder .step-content .custom-button.question-button{
  margin-top: 0;
}

.abs-tooltip-text {
  position: absolute;
  left: 370px;
  box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.16);
  width: 308px;
  background: #FFF;
  opacity: 1;
  z-index: 2000;
  padding: 10px;
  margin-top: -90px;
}

.grow-area-tooltip {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -1px;

  .abs-tooltip-text {
    width: 320px;
    margin-top: 8px;
    font-weight: normal;
    font-size: 14px;
    padding: 10px 0 15px;
    left: -253px;
  }
}

.box-pk-item.plants {
}

.container-label-custom-pot {
  max-width: 230px;
  margin: 0 auto 20px auto;
  margin-top: 13px;
  font-size: 18px;
  line-height: 22px;
  font-family: "Lato";
  -webkit-user-select: none;
  user-select: none;
  font-weight: bold;
  text-transform: uppercase;
}

.device-warning {
  display: none;
  background: #487629;
  position: absolute;
  z-index: 1000;
  padding: 10px;
  color: #FFF;
  font-weight: bold;
  height: 100%;
  font-size: 20px;

  .device-logo {
    width: 100%;
    text-align: center;
    opacity: 0.2;
    position: absolute;
    left: 0;
    bottom: 20%;
  }
}

@media only screen and (max-width: 999px) {
  .device-warning { display: block; }
}