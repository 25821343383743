@import "./../../../../../styles/global.scss";
.react-app-room-builder-nutrient-calculator-container {
  * {
    font-family: $Akzidenz;
  }
  .test-wrapper {
    display: flex;
    align-items: center;
    color: #888;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  /* Mouse-over effects */
  .slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  #input-box {
    border: none;
    width: 103px;
    height: 61px;
    font-size: 22px;
    text-align: center;
    color: $grey-input;
    font-family: $Akzidenz;
    border: 1px solid transparent;
  }

  .slidecontainer {
    padding-left: 10px;
    width: 100%; /* Width of the outside container */
    align-items: center;
    .n-c-track-slider {
      background-color: $green-secondary;
    }
    .n-c-track-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px;
      height: 20px;
      background: #ffffff;
      cursor: pointer;
      border-radius: 50%;
      border: 4px solid $green-secondary;
      margin-top: -8px;

    }
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      input[type="range"] {
        -webkit-appearance: none;
      }

      input[type="range"]::-webkit-slider-runnable-track {
        height: 3px;
        -webkit-appearance: none;
        appearance: none;
        color: $green-secondary;
      }
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        width: 20px;
        height: 20px;
        background: #ffffff;
        cursor: pointer;
        border-radius: 50%;
        border: 4px solid $green-secondary;
        margin-top: -8px;
        
      }
    }

    /** FF*/
    input[type="range"]::-moz-range-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px;
      background: $green-secondary;
      cursor: pointer;
      border-radius: 50%;
      border: 2px solid $green-secondary;
    }
    input[type="range"]::-moz-range-progress {
      background-color: $green-secondary;
    }
    input[type="range"]::-moz-range-track {
      background-color: #ced9bf;
    }
    /* IE*/
    input[type="range"]::-ms-fill-lower {
      background-color: $green-secondary;
    }
    input[type="range"]::-ms-fill-upper {
      background-color: #ced9bf;
    }
    .-slider-container- {
      width: 100%;
      
    }
    .slidecontainer-slide {
      background-color: $green-secondary;
      width: 0px;
      position: absolute;
      left: 0;
      top: 7px;
      z-index: 3;
      border-radius: 10px;
    }
    .slidecontainer-slide-bg {
      background-color: #eaebeb;
      width: 100%;
      position: absolute;
      left: 0;
      top: 7px;
      z-index: 2;
      border-radius: 10px;

    }
    
    .slider-input-type-range {
      
      -webkit-appearance: none; /* Override default CSS styles */
      appearance: none;
      width: 100%;
      background: transparent;
      background-color: transparent;
      border: none;
      outline: none; /* Remove outline */
      
      color: $green-secondary;
      margin-right: 10px;
      border-radius: 10px;
      position: relative;
      z-index: 7;
      left: +0px;
      top: 7px;
    }
    .slider-input-type-range,
    .slidecontainer-slide-bg,
      .slidecontainer-slide {
      height: 11px;

      
    }
  }
}
