@import "./../../../../styles/global.scss";
.wrapper-save-room-popup-rm-your-build {
  .modal-cstm-container {
    .modal-cstm-content {
      width: 569px;
      #save-room-popup-rm-your-build {
        @include flex-start;
        flex-direction: column;
        width: 100%;
        padding: 0 58px;
        .modal-cstm-data {
          padding-bottom: 20px;
          padding-left: 0;
          padding-right: 0;
        }
        .save-room-rm-dropdown-container {
          .dropdown {
            .my-dropdown {
              padding-bottom: 14px;
              .my-dropdown-inner {
                button {
                }
              }
            }
          }
        }

        .popup-room-description {
          width: 100%;
          textarea {
            width: 100%;
            height: 104px;
            resize: none;
          }
          padding-bottom: 21px;
        }
        .modal-buttons-save-room {
          width: 100%;
          height: 100px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          button {
            height: 70px;
            padding: 0px;
            text-align: center;
            font-size: 21px;
            line-height: 28px;
            font-weight: 700;
            border: none;
            border-radius: 0px;
            outline: none;
            text-transform: uppercase;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            background-color: #61a60e;
            color: #fff;
            width: 190px;
            &.btn-secondary {
              color: #487629;
              background-color: #fff;
              width: 175px;
              margin-right: 20px;
            }
          }
        }
      }
    }
    .original-owner-section {
      width: 100%;
      .modal-cstm-text {
      }
      .original-build-owner {
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        color: $green-var-2;
        padding: 27px 0 6px 0;
        text-transform: uppercase;
      }
      .modal-save-note {
        font-size: 15px;
        line-height: 18px;
        color: $idle-grey;
      }
    }
    .modal-cstm-shadow {
      height: 162px;
    }
    .save-popup-warning-content {
      width: calc(100% - 5px);
      margin-left: 2.5px;

      font-family: $latto;
      position: absolute;
      height: 361px;
      left: 0;
      bottom: 0;
      background-color: white;

      -webkit-box-shadow: 3px 6px 20px 0px rgba(0, 0, 0, 0.45);
      -moz-box-shadow: 3px 6px 20px 0px rgba(0, 0, 0, 0.45);
      box-shadow: 3px 6px 20px 0px rgba(0, 0, 0, 0.45);
      .save-popup-warning-inner {
        padding-bottom: 35px;
        padding-left: 39px;
        padding-right: 39px;
        padding-top: 20px;

        width: 100%;
        position: relative;
        .close-btn-warning {
          position: absolute;
          width: 52px;
          height: 52px;
          top: 0;
          right: 0;
        }
        .save-popup-warning-title {
          width: 100%;
          text-align: center;
          font-size: 32px;
          line-height: 32px;
          font-weight: bold;
          color: #ff1919;
        }
        .save-popup-warning-icon {
          height: 94px;
          padding-top: 17px;
          display: flex;
          justify-content: center;
        }
        .save-popup-warning-note {
          font-size: 18px;
          line-height: 22px;
          color: #707070;
          width: 100%;
          text-align: center;
          padding-bottom: 29px;
        }
      }
    }
  }
}
.modal-cstm-content {
  &[save-room-attr="true"] {
    width: 495px!important;
  }
}
