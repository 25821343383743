@import "../../../styles/global.scss";

.input-group {
  .custom-input {
    /* Remove basic styling */
    display: block;
    padding: 14px 15px 16px 15px;
    border: 0px;
  
    /* Layout-based Styling */
    color: $input-color;
    background: $input-background;
    border: 0.75px solid $light-grey;
    font-size: 20px;
    line-height: 27px;
    min-width: 300px;
    font-family: $latto;
    text-align: center;
    font-weight: $semibold;
    outline: none;
    &::placeholder {
      color: $input-color;
    }
  
    &:focus {
      border: 0.75px solid $green-primary;
    } 
  
    
    &[type='number'] {
      -moz-appearance:textfield;
    }
  
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
  
    &.variant-outline {
      font-size: 16px;
      color: black;
      height: 38px;
      background-color: $white;
      border-radius: 5px;
      font-family: $latto;
      border: 1px solid $input-color;
      padding: 0 16px;
      min-width: unset;
    }
    &.variant-min {
      width: 43px;
      height: 32px;
      border-color: transparent;
      font-family: $latto;
      font-size: 15px;
      line-height: 15px;
      color: $dark-grey;
      background-color: $thin-yellow;
      border-radius: 5px;
      min-width: unset;
      text-align: center;
      padding: 0 10px;
    }
    &.input-error {
      border: 1px solid $red-alert;
    }
  }
  
  .minus, .plus {
    border: 0.75px solid $light-grey;
    font-size: 20px;
    height: 59px;
    padding: 14px 15px 16px 15px;
    outline: none;
  }

  .minus {
    border-right: 0px;
  }

  .plus {
    border-left: 0px;
  }
  
}


