@import '../../../styles/global.scss';

.custom-switch-toggle {
    @include flex-all;
    margin-bottom: 25px;
    background: #e5e5e5;
    border: 0.75px solid $light-grey;
    border-radius: 50px;
    user-select: none;

    .switch-item {
        cursor: pointer;
        border-radius: 50px;
        height: 55px;
        width: 87px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
            margin-right: 0px;
        }

        .switch-item-label {
            padding: 20px;
            color: $light-grey;
            font-weight: $semibold;
            border-radius: 50px;
            font-family: $latto;
        }

        &.selected {
            flex-grow: 1;
            background: $green-primary;
            border-radius: 50px;

            .switch-item-label {
                color: white;
            }
        }
    }
}