@import "./../../../styles/global.scss";

$this-variant-height: 70px;
$this-variant-dr-width: 350px;
.dropdown-right {
    width: 100%;
}
@mixin dropdonw-input-text-style- {
    padding: 0 15px;
    color: $idle-grey;
    font-size: 24px;
}
.my-dropdown {
    padding-bottom: 20px;
    .my-dropdown-inner {
        min-width: 100px;
        border-radius: 5px;

        height: $this-variant-height;
        background-color: $white;
        svg {
            width: 32px;
            height: 32px;
        }
        width: $this-variant-dr-width;
        .dropdown-left {
            @include flex-all;
            font-family: $latto;
            font-size: 24px;
            flex: 1;
            input[type="text"] {
                width: 100%;
                appearance: none;
                outline: none;
                -webkit-tap-highlight-color: transparent;
                border: none;
                @include dropdonw-input-text-style-();
                &::placeholder {
                    opacity: 0.6;
                }
            }
        }

        @include flex-start;

        .dropdown-right {
            width: 100%;
            padding-right: 1px;
            @include flex-all;
            height: $this-variant-height;
            .dropdown-right-inner {
                @include flex-all;
                background-color: $green-primary-new;
                width: calc(#{$this-variant-height});
                height: calc(#{$this-variant-height});
            }
        }
        button {
            width: 100%;
            border-radius: 0;
        }
        .dropdown-toggle {
            padding: 0 !important;
            &.btn-primary {
                background-color: unset !important;
                border-color: unset !important;
            }
            &.btn-success {
                background-color: unset !important;
                border-color: $green-stroke;
                border-width: 1px;
                border-right: none;
                -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            }
        }
        .dropdown-toggle::after {
            display: none !important;
        }
    }
    &.variant-2 {
        .my-dropdown-inner {
            $this-variant-height: 37px;
            height: $this-variant-height;
            .dropdown-left {
                @include generic-latto-16;
            }
            .dropdown-right {
                height: $this-variant-height;
                .dropdown-right-inner {
                    width: calc(#{$this-variant-height} - 2px);
                    height: calc(#{$this-variant-height} - 2px);
                }
            }
        }
    }
}
.dropdown-container-medium {
    .my-app-dropdown-options > a {
        font-family: $latto;
        height: $this-variant-height;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 24px;
        line-height: 29px;
        padding: 0 15px;
        color: #707070;
        &:active,
        &:hover {
            background-color: $highlight-dr-color;
            color: inherit;
        }
    }
    .my-dropdown-inner .dropdown-left input[type="text"] {
        font-size: 24px !important;
        line-height: 29px;
        font-weight: 400;
        padding: 0px 10px;
    }
}
.my-app-dropdown-options {
    width: $this-variant-dr-width;
    & > a {
        @include generic-latto-16;
        @include dropdonw-input-text-style-();
        height: $this-variant-height;
        text-align: left;
        display: block;
        line-height: $this-variant-height;
        &:active,
        &:hover {
            background-color: $highlight-dr-color;
            color: inherit;
        }
    }
    &.show {
        max-height: #{70px * 4};
        overflow-y: auto;
        @include container-scroll-type-scrollbar;
    }
}
.dropdown-inherit-size {
    width: 100%;
    &.dropdown-container-medium {
        width: 300px;
        max-width: 100%;
    }
    .dropdown {
        width: 100%;
        .my-dropdown {
            width: 100%;
            .my-dropdown-inner {
                width: 100%;
                button {
                    width: 100%;
                }
            }
        }
        .my-app-dropdown-options {
            width: 100%;
        }
    }
}

.dropdown-80 {
    width: 80% !important;
}

/* .dropdown-container-medium {
    input {
        font-size: 18px !important;
    }
} */
