@import "./../../../../styles/global.scss";

.modal-cstm-share {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 99;
    font-family: $latto;
    flex-wrap: wrap;

    .modal-cstm-container-share {
      @include flex-all;
      height: 100%;


      .modal-cstm-content-share {
        width: 727px;
        max-width: 100vw;
        min-width: 250px;
        height: auto;
        background-color: #fff;
        position: relative;
        .modal-cstm-top-share {
            height: 80px;
            display: flex;
            align-items: center;
            padding-left: 25px;
            background-color: $very-light-grey;
            .modal-close {
                width: 80px;
                height: 100%;
            }
            .modal-share-title {
                font-size: 25px;
                line-height: 30px;
                font-weight: bold;
                color: $green-link;
            }
        }
        .modal-cstm-top-bottom {
            -webkit-box-shadow: 8px 3px 10px 0px rgba(0,0,0,0.16);
            -moz-box-shadow: 8px 3px 10px 0px rgba(0,0,0,0.16);
            box-shadow: 8px 3px 10px 0px rgba(0,0,0,0.16);
            background-color: white;
            padding: 0 45px;
            .modal-content-title {
                height: 85px;
                width: 100%;
                font-size: 32px;
                line-height: 39px;
                color: black;
                font-weight: bold;
                text-align: center;
            }
            .modal-content-paragraph {
                width: 100%;
                font-size: 22px;
                line-height: 27px;
                color: $idle-grey;
                text-align: center;
            }
            .modal-share-link-bar {
                padding-top: 23px;
                .modal-share-link-body {
                    height: 57px;
                    border: 2px solid $light-grey-var-2;
                    padding-left: 20px;
                    &:active {
                        border-color: $green-link;
                      }
                    &:focus {
                        border-color: $green-link;
                      }
                    &:target {
                        border-color: $green-link;
                    }

                    .share-link {
                        color: black;
                        line-height: 27px;
                        font-size: 22px;
                    }
                    .share-link-copy-icon {
                        height: 57px;
                        width: 57px;
                    }
                    
                }
            }
            .share-icon-list {
                width: 259px;
                padding-bottom: 40px;
                padding-top: 25px;
                .share-icon-item {
                    width: 71px;
                    height: 71px;
                }
            }
        }
      }
    }
  }