@import "../../styles/global.scss";
@-moz-keyframes rotateButton {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateButton {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotateButton {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#auth {
  font-family: $latto;
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100vh;
  min-height: 500px;
  overflow: hidden;
  .left-side-auth {
    width: calc(100% - 587px);
    background-image: url("../../assets/image/loginBg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    height: 100%;
    .logo-new {
      @include flex-all;
      width: 100%;
      img {
        
      }
    }
    .version-txt {
      font-family: $Montserrat;
      font-size: 15px;
      letter-spacing: -0.4px;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      color: #fff;
      position: absolute;
      left: 16px;
      bottom: 12px;
      font-weight: 400;
    }
    .left-content {
      margin-top: auto;
      margin-bottom: 40px;
      padding-left: 64px;
      .logo {
        max-width: 474px;
        margin-bottom: 15px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .motto {
        color: #fff;
        font-size: 40px;
        line-height: 48px;
        font-weight: 700;
        text-shadow: 2px 3px 10px rgba(0, 0, 0, 0.61);
        max-width: 980px;
      }
    }
    // @media (min-width: 1920px) {
    //     background-size: auto 100%;
    // }
  }
  .right {
    width: 587px;
    height: 100%;
    .right-content {
      width: 100%;
      padding: 57px 0px 40px 0px;
      background-color: $green-primary;
      height: 100%;
      overflow: auto;
      > div:not(.forms-container) {
        padding: 0px 45px 0px 65px;
      }
      .text {
        font-size: 24px;
        line-height: 29px;
        color: #fff;
        font-weight: 400;
        text-align: center;
        margin-bottom: 28px;
      }
      .button-section {
        text-align: center;
        padding-top: 5px;
      }
      .separator {
        text-align: center;
        color: #fff;
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 2px;
      }
      .forms-container {
        .form-tabs {
          display: flex;
          width: 100%;
          flex-direction: row;
          font-size: 21px;
          color: #fff;
          margin-bottom: 13px;
          padding-left: 65px;
          .form-item-tab {
            padding-bottom: 9px;
            margin-right: 17px;
            &[active="1"] {
              border-bottom: 3px solid #fff;
            }
            cursor: pointer;
          }
        }
        .form-content {
          form {
            .form-container {
              width: calc(100% - 30px);
              overflow-x: hidden;
              overflow-y: auto;
              // height: calc(100vh - 588px);
              // max-height: calc(100vh - 644px);
              padding-left: 65px;
              padding-right: 10px;
              &::-webkit-scrollbar-track {
                background: #e3e3e3;
                border-radius: 27px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #666;
                outline: none;
                border-radius: 27px;
              }
              &::-webkit-scrollbar {
                width: 12px;
              }
            }
          }
          .checkbox-input {
            margin-bottom: 12px;
            input  {
              & :checked {
                background-color: $green-primary;
              }

            }
          }
          .input-password-group {
            position: relative;
            input {
              padding-right: 54px;
            }
            &:hover span {
              opacity: 1;
            }
            span {
              opacity: 0;
              position: absolute;
              @include flex-all;
              width: 54px;
              height: 54px;
              padding: 14px;
              top: 0;
              right: 0;
              cursor: pointer;
              > img {
                width: auto;
                max-width: 100%;
                height: auto;
              }
            }
          }
          input {
            width: 100%;
            height: 54px;
            border: none;
            outline: none;
            border-radius: 0px;
            background-color: #fff;
            color: #474747;
            padding: 0 20px;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            margin-bottom: 10px;
          }
          .form-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 65px;
            padding-bottom: 58px;
            margin-top: 10px;
            button {
              margin-right: 10px;
            }
            .simple-url {
              color: #fff;
              font-size: 18px;
              line-height: 22px;
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
          .login-form,
          .register-form {
            display: none;
            &.active {
              display: block;
            }
          }
          .input-double {
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-direction: row;
            input {
              width: calc(50% - 5px);
            }
          }
        }
      }
    }
  }
  .simple-btn {
    background-color: #ffffff;
    height: 56px;
    text-align: center;
    padding: 0 32px;
    font-size: 20px;
    font-weight: 400;
    color: #474747;
    border-radius: 0px;
    outline: none;
    border: none;
    &.success-btn {
      background-color: #61a61c;
      color: #fff;
    }
    &#submit-account-btn {
      width: 140px;
      &[status="inactive"] {
        opacity: 0.5;
      }
    }
    &#login-btn {
      width: 126px;
    }
    &[disabled] {
      pointer-events: none;
      opacity: 0.65;
    }
    > span {
      width: 36px;
      height: 36px;
      box-sizing: border-box;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 50%;
      border: 5px solid;
      border-left-color: transparent;
      display: block;
      margin: 0 auto;
      -webkit-animation: rotateButton 1s linear infinite;
      -moz-animation: rotateButton 1s linear infinite;
      animation: rotateButton 1s linear infinite;
    }
  }
  .checkbox-input {
    display: flex;
    align-items: center;
    position: relative;
    span {
      display: block;
      width: 34px;
      height: 34px;
      margin-right: 16px;
      border: 1px solid #707070;
      input[type="checkbox"] {
        width: 32px !important;
        height: 32px !important;
        border: none !important;

        position: absolute;
        /* opacity: 0;
        cursor: pointer;
        height: 0 !important;
        width: 0 !important; */
      }
    }
    .label-checkbox {
      color: #fff;
      font-size: 18px;
      line-height: 22px;
      width: calc(100% - 56px);
    }

    /* input:checked ~ span {
      background-color: #2196F3;
    }
    span:after {
      content: "";
      position: absolute;
      display: none;
    }
    input:checked ~ span:after {
      display: block;
    }
    span:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    } */
  }
  .simple-url,
  .label-checkbox {
    a,
    a:hover,
    a:visited,
    a:active {
      text-decoration: underline;
      cursor: pointer;
      color: #fff;
    }
  }
}
@media(max-width: 768px) {
  #auth {
    flex-direction: column;
    height: auto;
    .left-side-auth {
      width: 100%;
      height: auto;
      .logo-new img {
        max-width: 70%;
      }
    }
    .right {
      width: 100%;
      height: auto;
      .right-content {
        height: auto;
        padding: 37px 0px 40px 0px;
        .forms-container .form-content form .form-container {
          width: 100%;
          max-height: none;
          padding: 0 35px;
        }
      }
    }
  }
}