@import "./../../../../../styles/global.scss";

.nuterient-calculator-counter {
  > .flex-start.flex-wrap {
    width: 210px;
    height: 63px;
  }
  .inline-group {
    max-width: 9rem;
    padding: 0.5rem;
    margin: 0 auto;
  }

  .inline-group .form-control {
    text-align: center;
  }

  .quantity {
    background-color: #eaebeb;
  }

  .form-control[type="number"]::-webkit-inner-spin-button,
  .form-control[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  max-width: 100vw;
  .nuterient-calculator-label {
    width: 265px;
    text-align: center;
    font-size: 27px;
    line-height: 29px;
  }
  .form-control {
    &.quantity {
      width: 80px;
      height: 63px;
      text-align: center;
      font-family: $Akzidenz;
      margin: 0px;
      border-color: $very-light-grey;
      background-color: transparent !important;
      font-size: 30px;
    }
  }
  button {
    border-radius: 0px;
    width: 63px;
    height: 63px;
    background-color: $very-light-grey;
    border: none;
  }
  .btn-calc-plus {
    font-size: 36px !important;
    font-weight: bold;
  }
}
