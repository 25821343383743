@import "./../../../../styles/global.scss";
@-moz-keyframes rotateButton {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateButton {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotateButton {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.modal-cstm {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 99;
  font-family: $latto;
  &[forgotpassword="true"] {
    .modal-cstm-container {
      .modal-cstm-content {
        font-family: $latto;
        .modal-cstm-data {
          padding: 0 45px 42px;
          .form-forgot-password {
            padding-top: 32px;
            .input--label {
              position: relative;
              &:first-child {
                margin-bottom: 19px;
              }
              .input-title {
                font-size: 22px;
                line-height: 27px;
                color: #707070;
                font-weight: 400;
                text-align: left;
                margin-bottom: 9px;
              }
              > input {
                font-size: 22px;
                line-height: normal;
                font-family: $latto;
                font-weight: 400;
                color: #474747;
                border: 1px solid #487629;
                height: 70px;
                width: 100%;
                outline: none;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                border-radius: 0px;
                padding: 0px 20px;
              }
              span.error-input-label {
                text-align: left;
                display: block;
                color: red;
                position: absolute;
                bottom: -23px;
                font-size: 16px;
              }
            }
            .captcha-cstm {
              margin-top: 40px;
              img {
                height: 56px;
                display: block;
                margin: 0 auto;
              }
              .reload-captcha-cstm {
                text-align: center;
                font-size: 22px;
                font-weight: 700;
                line-height: normal;
                margin-top: 14px;
                color: #000;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  &[extrasForBuild="true"] {
    .modal-cstm-container {
      .modal-cstm-content {
        width: 1051px;
        .modal-cstm-data {
          padding: 0 45px 36px;
        }
        #extras-for-build-popup {
          .modal-cstm-extra-products {
            width: 100%;
            margin-bottom: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            .item-extra-prod {
              width: 206px;
              margin-right: 24px;
              background-color: #f1f1f1;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
              &:last-child {
                margin-right: 0px;
              }
              .item-extra-prod-content {
                padding: 20px 10px;
                text-align: center;
                .img-extra-prod-item {
                  background-color: #fff;
                  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                  width: 156px;
                  height: 156px;
                  margin: 0 auto;
                  img {
                    object-position: center;
                    object-fit: contain;
                    max-width: 100%;
                    height: auto;
                  }
                }
                .title-extra-prod-item {
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 700;
                  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                  text-align: center;
                  color: #000;
                  margin-top: 14px;
                  margin-bottom: 7px;
                }
                .price-extra-prod-item {
                  font-size: 25px;
                  line-height: normal;
                  font-weight: 700;
                  text-align: center;
                  color: #487629;
                  margin-bottom: 12px;
                }
                .btn-extra-prod-item {
                  width: 159px;
                  background-color: #00abc8;
                  cursor: pointer;
                  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
                  height: 37px;
                  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                  font-size: 20px;
                  line-height: 24px;
                  border: none;
                  outline: none;
                  padding: 0px;
                  text-align: center;
                  color: #fff;
                  position: relative;
                  .already-in-cart {
                    display: none;
                  }
                  &[isAdded="1"] {
                    position: relative;
                    width: 100%;
                    text-align: left;
                    padding-left: 15px;
                    .already-in-cart {
                      position: absolute;
                      top: 3px;
                      right: 8px;
                      display: block;
                      svg {
                        width: 25px;
                      }
                    }
                  }
                  > span {
                    width: 24px;
                    height: 24px;
                    box-sizing: border-box;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    border-radius: 50%;
                    border: 4px solid;
                    border-left-color: transparent;
                    display: block;
                    margin: 0 auto;
                    -webkit-animation: rotateButton 1s linear infinite;
                    -moz-animation: rotateButton 1s linear infinite;
                    animation: rotateButton 1s linear infinite;
                  }
                }
              }
            }
          }
        }
        .modal-cstm-top {
          background-image: url(./../../../../assets/image/popup-header-var-2.png);
        }
      }
    }
  }
  &[step="BUILD_SAVED_STEP"] {
    // top: 119px;
    // left: 395px;
    .modal-cstm-container {
      .modal-cstm-content {
        width: 1050px;
        #save-room-popup-rm-your-build {
          padding: 0 25px;
        }
        .modal-cstm-top {
          background-image: url(./../../../../assets/image/popup-header-var-2.png);
          // popup-header-var-2
        }
      }
    }
    .title-dash {
      font-size: 30px;
      color: #000;
      font-weight: 700;
      text-transform: uppercase;
    }
    .build-h-item {
      display: flex;
      flex-direction: row;
      width: 100%;
      background-color: #f1f1f1;
      margin-bottom: 38px;
      margin-top: 23px;
      .left-build-h {
        width: 780px;
        padding-left: 18px;
        padding-right: 26px;
        padding-top: 9px;
        .build-h-item-details {
          width: 100%;
          padding-top: 17px;
          .row-build-h {
            width: 100%;
            display: flex;
            flex-direction: row;
            .item-row-build-h {
              width: 33.33%;
              font-size: 20px;
              font-weight: 700;
              &:not(:last-child) {
                margin-bottom: 35px;
              }
              .name-item-row-build {
                color: #000;
              }
              .values-item-row-build {
                color: #61a60e;
                margin-top: -4px;
                display: flex;
                max-width: calc(100% - 10px);
                align-items: center;
                flex-direction: row;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
                .counter-trays {
                  @include flex-all;
                  width: 24px;
                  height: 24px;
                  background-color: #61a60e;
                  border-radius: 50%;
                  margin-right: 4px;
                  color: #fff;
                  text-align: center;
                  padding-bottom: 2px;
                }
                .value-trays {
                  width: calc(100% - 30px);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .image-item-row-build {
                width: 83px;
                height: 80px;
                margin-top: 4px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
            }
          }
          .bottom-section-build-h {
            display: flex;
            align-items: center;
            width: 100%;
            height: 46px;
            flex-direction: row;
            button {
              border: none;
              border-radius: 0px;
              outline: none;
              height: 100%;
              padding-left: 12px;
              padding-right: 12px;
              font-size: 21px;
              color: #fff;
              font-weight: 700;
              box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
              margin-right: 20px;
              &.reorder-btn {
                background-color: #1babc8;
              }
              &.buy-room-btn {
                background-color: #61a60e;
              }
            }
            .date-build-h {
              font-size: 16px;
              color: #000;
            }
          }
        }
      }
    }
  }
  &[step="MULTI_STEP"] {
    // top: 119px;
    // left: 395px;
    .modal-cstm-container {
      .modal-cstm-content {
        width: 879px;
        height: 737px;
        .modal-cstm-top {
          background-image: url(./../../../../assets/image/popup-header-var-3.png);
          // popup-header-var-2
        }
        .modal-cstm-logo {
          background-color: #fff;
          @include flex-all;
          width: 141px;
          height: 141px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          top: 105px;
          img {
            max-width: 86px;
          }
        }

        .modal-cstm-data {
          .modal-cstm-text {
            font-size: 22px;
            line-height: 27px;
            margin-top: 57px;
            > span {
              display: block;
            }
            > span:first-child {
              margin-bottom: 26px;
            }
            span.bolded {
              display: block;
              font-weight: bold;
            }
            > div.center {
              font-weight: 700;
              text-align: center;
              margin-top: 20px;
            }
          }
        }
        #save-room-popup-rm-your-build {
          padding: 0 72px;
          .modal-cstm-data {
            height: 319px;
            margin-top: 80px;
            .modal-cstm-title {
              > sup {
                top: unset;
                font-size: large;
                vertical-align: super;
              }
            }
          }
          .modal-buttons-save-room {
            // margin: 27px 0;
          }
          .fake-navigation {
            display: inline-block;
            width: 100%;
            text-align: center;
            margin-top: 30px;
            margin-bottom: 28px;
            .items-fake-navigation {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 18px;
              .item-fn {
                width: 18px;
                height: 100%;
                border-radius: 50%;
                background: #f0f0f0;
                margin-right: 20px;
                border: 1px solid #a5a5a5;
                &:last-child {
                  margin-right: 0px;
                }
                &.active {
                  background: #cbcbcb;
                }
              }
            }
          }
        }
      }
    }
  }
  &[nutrient="true"] {
    .modal-cstm-container {
      .modal-cstm-content {
        .modal-cstm-logo {
          img {
            max-width: 113px;
          }
        }
      }
    }
  }
  &[thirdparty="true"] {
    .modal-cstm-container {
      .modal-cstm-content {
        .modal-cstm-logo {
          img {
            max-width: 98px;
          }
        }
      }
    }
  }
  &[buildcomponent="true"] {
    .modal-cstm-container {
      .modal-cstm-content {
        .modal-cstm-logo {
          img {
            max-width: 87px;
          }
        }
      }
    }
  }

  &[step="READY_TO_GO_STEP"] {
    // top: 119px;
    // left: 395px;
    .modal-cstm-container {
      .modal-cstm-content {
        width: 560px;
        .modal-cstm-data {
          .modal-cstm-text {
            font-size: 22px;
            line-height: 27px;
            margin-top: 37px;
            margin-bottom: 9px;
            > span:first-child {
              margin-bottom: 26px;
            }
            > span {
              display: block;
              sup {
                top: unset;
                font-size: x-small;
                vertical-align: super;
              }
            }
          }
        }
        #save-room-popup-rm-your-build {
          padding: 0 38px;
        }
      }
    }
  }
  &.wrapper-save-room-popup-rm-your {
    .modal-cstm-container {
      .modal-cstm-content {
        width: 563px;
        .modal-cstm-data {
          padding: 0 45px 20px;
        }
        .modal-buttons {
          margin-top: 15px;
        }
      }
    }
  }
  .modal-cstm-container {
    @include flex-all;
    height: 100%;
    .modal-cstm-content {
      height: auto;
      width: 497px;
      background-color: #fff;
      position: relative;
      .modal-cstm-top {
        height: 162px;
        -webkit-box-shadow: 3px 6px 20px 0px rgba(0, 0, 0, 0.45);
        -moz-box-shadow: 3px 6px 20px 0px rgba(0, 0, 0, 0.45);
        box-shadow: 3px 6px 20px 0px rgba(0, 0, 0, 0.45);
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          visibility: hidden;
        }
        background-image: url(./../../../../assets/image/modalTopBg.png);
        background-size: cover;
      }
      .modal-cstm-logo {
        height: 102px;
        width: 102px;
        border-radius: 50%;
        position: absolute;
        top: 106px;
        left: 0;
        right: 0;
        margin: 0 auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      .modal-cstm-data {
        margin-top: 52px;
        width: 100%;
        padding: 0 45px 64px;
        text-align: center;
        .modal-cstm-title {
          font-size: 32px;
          line-height: 33px;
          color: #000;
          font-weight: 700;
          text-transform: uppercase;
          padding-top: 21px;
        }
        .modal-cstm-text {
          font-size: 22px;
          line-height: 29px;
          color: #707070;
          font-weight: 400;
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          margin-top: 20px;
          .missing-req-fields {
            position: relative;
            width: calc(100% + 26px);
            left: -13px;
          }
          .center {
            color: #707070;
            font-weight: 700;
            margin-top: 20px;
          }
        }
      }
      .modal-buttons {
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 50px;
        button {
          height: 70px;
          padding: 0px;
          text-align: center;
          font-size: 21px;
          line-height: 28px;
          font-weight: 700;
          border: none;
          border-radius: 0px;
          outline: none;
          text-transform: uppercase;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          background-color: #61a60e;
          color: #fff;
          width: 190px;
          &.btn-secondary {
            color: #487629;
            background-color: #fff;
            width: 175px;
            margin-right: 20px;
          }
        }
      }
      .input-pop-cstm {
        padding: 0 40px;
        width: 100%;
        input {
          padding: 0px 12px 7px;
        }
        input,
        textarea {
          color: #707070;
          font-size: 24px;
          font-weight: 400;
          border: 1px solid #d6d6d6;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          height: 70px;
          width: 100%;
          outline: none;
          &[error="yes"] {
            border: 1px solid red;
          }
        }
        textarea {
          height: 104px;
          resize: none;
          margin-top: 14px;
          line-height: 29px;
          padding: 7px 12px 7px;
        }
      }
    }
  }
}
