@import "./../../../styles/global.scss";

.nav-sub-menu {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: auto;
  height: 64px;
  margin-left: 395px;
  border: 0.75px solid $light-grey;
  background: $white;
  flex: 0 1;
  z-index: 5;
  box-shadow: 0px 3px 6px rgba(0,0,0,.16);
  .nav-item {
    min-width: 18.843%;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: -34px;

    &:first-child {
      margin-left: 0px;
      .nav-sub-item {
        padding-left: 20px;
      }
    }

    &:last-child {
      .nav-sub-item {
        margin-right: 0px;
      }
    }
    &.recommended-builds-header {
      min-width: unset;
      width: auto;
      flex: 1;
      .recommended-build-label {
        display: flex;
        flex-direction: column;
        span {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          
        }
      }
    }
    &.tray-size-header {
      min-width: 18.843%;
      width: 18.843%;
      .nav-info-label {
        width: calc(100% - 25px);
      }
      .tray-text-header {
        flex: 1;
        overflow: hidden;
        font-family: $latto;
        .rounder-div-green {
          width: 17px;
          height: 17px;
          background-color: $green-primary;
          border-radius: 17px;
          color: white;
          font-weight: bold;
          margin-right: 5px;
          font-size: 14px;
        }
        .main-text-tray-header {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .nav-sub-item {
      @include flex-start;
      padding-right: 7px;
      width: 100%;
      background-color: $white;
      width: 100%;
      height: 100%;
      padding-left: 29px;
      display: flex;
      align-items: center;
      color: $light-grey;
      font-family: $latto;
      margin-right: 34px;

      &::after {
        position: absolute;
        top: -1px;
        right: 9px;
        content: '';
        z-index: 1;
        width: 25px;
        height: 0;
        border-style: solid;
        border-width: 34px 0 34px 25px;
        border-color: transparent transparent transparent $white;
      }

      &::before {
        position: absolute;
        top: -1px;
        right: 8px;
        content: '';
        z-index: 1;
        width: 25px;
        height: 0;
        border-style: solid;
        border-width: 34px 0 34px 25px;
        border-color: transparent transparent transparent $light-grey;
      }

      .nav-step {
        font-size: 12px;
        font-family: $sf-compact-display;
        font-weight: $semibold;
      }
      .nav-label {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        font-family: $sf-compact-display;
        white-space: nowrap;

        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        font-family: "Lato";
        white-space: nowrap;
        color: $light-grey;
        text-shadow: 0px 3px 6px rgba(0,0,0,.16);
        
        &.text-small {
          font-size: 16px;
          white-space: nowrap;
          color: $green-primary-new;
        }
      }

      .nav-info-label {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        font-family: $sf-compact-display;

        &.text-small {
          white-space: nowrap;
          color: $green-primary-new;
        }

        .info-sup {
          font-size: 10px;
          background: $green-primary;
          color: $white;
          padding: 2px 4px;
          border-radius: 50px;
          display: inline;
          vertical-align: super;
        }
      }

      .nav-front-icon {
        svg {
          margin-right: 14px;
          fill: $light-grey;
        }

        &.step-completed {
          svg {
            fill: $green-primary;
          
            path {
              fill: $green-primary;
            }
          }
        }
      }

      .nav-icon {
        @include filled-check-circle($white, $green-primary);
        &.icon-filled {
          @include filled-check-circle($green-primary, $white)
        }
      }
    }
    .nav-sub {
      overflow: hidden;
      flex: 1;
      .nav-info-label {
        width: calc(100% - 10px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &.nav-item-left {
      .nav-sub-item {
        padding-left: 47px;
        background: $white;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        background-color: $grey;
        border-left: 27px solid $background-color;
        border-top: 37px solid transparent;
        border-bottom: 37px solid transparent;
      }
    }
    &.nav-item-right {
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        background-color: $background-color;
        border-left: 25px solid $grey;
        border-top: 37px solid transparent;
        border-bottom: 37px solid transparent;
      }
    }
    &.is-completed-- {
      &:not(.is-selected) {
        .nav-label {
          color: $green-primary-new;
        }
        // .nav-front-icon {
        //   svg {
        //     path {
        //       fill: $green-primary;
        //     }
        //   }
        // }
      }
    } 
    &.is-selected {
      .nav-sub-item {
        background-color: $background-color;
        color: $white;
        background: $green-primary;

        .nav-sub {
          .nav-label {
            color: white;
            &.text-small {
              font-size: 16px;
              white-space: unset;
            }
            font-size: 16px;
            line-height: 22px;
            font-weight: 300;
            font-family: "Lato";
            white-space: nowrap;
            
          }
        }

        &::after {
          border-color: transparent transparent transparent $green-primary;
        }

        .nav-front-icon {
          svg {
            fill: $white;

            path {
              fill: $white;
            }
          }
        }

        .nav-sub {
          .nav-info-label {
            display: none;
          }
        }

      }

      &.nav-item-left {
        &::before {
          background-color: $green-primary;
        }
      }
      &.nav-item-right {
        &::after {
          border-left: 27px solid $green-primary;
        }
      }
      .nav-icon {
        @include filled-check-circle(black, transparent);
      }
    }

    &:last-child {
      .nav-sub-item {
        &::after {
          content: none;
        }
  
        &::before {
          content: none;
        }
      }
    }

  }

  & > .nav-item:last-child {
    padding-right: 0;
  }
  .irigigation-nav-item-cl {
    width: 18.843%!important;
  }

  .nav-item.irigigation-nav-item-cl .nav-sub .nav-info-label{
    width: 85%;
  }

  &[grow-id="1"] {
    .pot-or-medium-header {
      width: 18%;
    }
    .zonning-style-header {
      width: 16.5%;
      min-width: unset;
    }
  }
}
