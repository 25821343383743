@import "../../../../../styles/global.scss";

.react-app-room-builder-nutrient-calculator-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .img-vegetativeFoliar {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(./media/VegFoliar.png);
  }
  .img-v1logo {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(./media/V1.png);
  }
  .img-v2logo {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(./media/V2.png);
  }
  .img-bloomFoliarLogo {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(./media/BloomFoliar.png);
  }
  .img-b1logo {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(./media/B1.png);
  }
  .img-b2logo {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(./media/B2.png);
  }
  .img-fullTiltLogo {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(./media/FullTilt.png);
  }

  
  hr {
    width: 100%;
  }
  .App-nutrient-calculator {
    text-align: center;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    flex: 1;
    @include container-scroll-type-scrollbar;
  }

  .App-wrapper {
    width: 100%;
  }

  .slider-wrapper {
    padding: 20px;
    text-align: left;
    height: 100%;
    display: grid;
    grid-template-rows: 0.5fr 1fr;
  }

  .alert-danger {
    background-color: red;
    border-radius: 0;
    color: #ffffff;
    font-weight: bold;
  }

  .card-body {
    background-color: #ebebeb;
  }

  .btn {
    border-radius: 0;
  }

  .card-body {
    padding: 0;
  }

  #green-btn {
    border: none;
    color: #ffffff;
    padding: 10px 50px;
  }

  hr {
    color: #a7a9ac;
    background-color: #a7a9ac;
  }
  .v-btn {
    background-color: $green-primary-n-c;
    border: none;
  }
  .btn-lg,
  .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0px;
  }
  .btn-lg + .dropdown-toggle-split,
  .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0px;
  }
  .btn-lg-imp {
    min-height: 66px;
    font-size: 25px;
    line-height: 30px;
    border-radius: 0;
  }
  .aqua-btn {
    background-color: $blue-bg;
    border: none;
  }

  #b-btn {
    background-color: $blue-bg;
    border: none;
    color: #ffffff;
    padding: 10px 50px;
  }
  * {
    box-sizing: border-box;
  }

  // utils
  .width-height-100 {
    width: 100%;
    height: 100%;
  }
  .background-100 {
    background-color: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;
    z-index: 99;
  }
  .text-center {
    text-align: center;
  }
  .flex-start {
    @include flex-start;
  }
  .flex-end {
    @include flex-end;
  }
  .flex-between {
    @include flex-between;
  }
  .flex-all {
    @include flex-all;
  }
  .flex-all-column {
    @include flex-all;
    flex-direction: column;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-evenly {
    justify-content: space-evenly;
  }
  .align-start {
    align-items: flex-start;
  }
  .flex-1 {
    flex: 1;
  }
  .p-right-50 {
    padding-right: 50px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .position-relative {
    position: relative;
  }
  .all-100 {
    width: 100%;
    height: 100%;
  }
  .top--100 {
    top: -100%;
  }
  .display-none {
    display: none;
  }

  .two-group-wrapper {
    & > div:first-child,
    & > button:first-child {
      margin-right: 227px;
    }
  }
  .flex-all {
    @include flex-all;
  }
  .select-none {
    user-select: none;
  }
  .absolute-bottom {
    position: absolute;
    bottom: 0;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
  .absolute-top {
    position: absolute;
    top: 0;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }

  .section-title {
    color: black;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 68px;
    font-weight: 700;
  }
  .page-mg {
    margin-top: 60px;
    max-width: 100vw;
  }
  .page-container {
    width: 100%;
    flex-direction: row;
  }
  .row {
    padding-top: 47px;
  }
  .row-2 {
    padding-top: 16px;
    padding-bottom: 11px;
  }
  .card-deck {
    margin-right: 0 !important;
    margin-left: 0 !important;
    button {
      color: white !important;
    }
  }
  .note-question {
    font-size: 18px;
    font-family: $Akzidenz;
    margin-bottom: 35px;
  }
  .bold {
    font-weight: bold;
  }
  .center-paragraph {
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    font-family: $latto;
    font-weight: bold;
    padding: 10px 0px;
    margin: 0;
    &.first-paragraph {
      margin-top: 12px;
    }
    &.multiline {
      padding: 0 20px;
      line-height: 44px;
    }
    // &.max-width {
    //   width: ;
    // }
  }
  .extend-section-nc {
    font-family: $latto;
    flex-direction: column;
    position: relative;
    height: auto;
    width: 100%;
    &[active="0"] {
      height: 57px;
      .content-section-nc {
        display: none;
      }
      .expand-calculations img { 
        transform: rotate(180deg);
      }
    }
    .expand-calculations {
      position: absolute;
      top: 16px;
      right: 0;
      cursor: pointer;
      img {
        transform: rotate(0deg);
      }
    }
    .paragram-extend-nc {
      width: 100%;
      text-align: center;
      font-size: 25px;
      line-height: 24px;
      font-family: $latto;
      font-weight: bold;
      padding: 10px 0px;
      margin: 0;
      cursor: pointer;
    }
    .content-section-nc {
      display: block;
      width: 100%;
      text-align: center;
      .txt-section-nc {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: #54565A;
        text-align: center;
        margin-top: 9px;
        margin-bottom: 46px;
      }
      .manipulation-data-section-nc {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        .counter-slider {
          width: 50%;
          min-width: auto;
          .slider-wrapper {
            min-height: auto;
            background-color: transparent;
            display: flex;
            padding: 0px;
            justify-content: unset;
            > .flex-all {
              display: block;
              width: 260px;
              margin-right: 20px;
              h5.slider-label {
                font-size: 16px;
                font-weight: 500;
                line-height: 26.4px;
                color: #54565A;
                text-transform: uppercase;
                margin-block-start: 0px;
                margin-block-end: 0px;
                width: 100%;
              }
            }
            .test-wrapper {
              width: calc(100% - 280px);
            }
          }
        }
        .switch-volume-ctn {
          width: 50%;
          padding-left: 2%;
          display: flex;
          span {
            font-size: 16px;
            font-weight: 500;
            line-height: 26.4px;
            color: #54565A;
            text-transform: uppercase;
            display: block;
            width: 187px;
            margin-right: 20px ;
            text-align: left;
          }
        }
      }
      
    }
  }

  .center-paragraph-var-2 {
    width: 100%;
    text-align: center;
    font-size: 22px;
    line-height: 27px;
    margin-top: 36px;
  }

  .distance-1 {
    width: 100%;
    height: 30px;
  }

  /** 
    buttons group
  */
  /* Square number buttons "FLUSH" */
  .btn-square {
    margin-top: 10px;
    width: 120px;
    height: 120px;

    background-color: #f2f2f2;
    color: #77777b;
    border: 1px solid #f2f2f2;
    border-radius: 0 !important;
    font-size: 35px;
    line-height: 35px;
    &.-btn-selected,
    &:hover {
      background-color: transparent;
      border: 1px solid #77777b;
    }
  }
  .btn-group {
    & > .btn-square {
      margin-right: 10px !important;
    }
    & > .btn-square:last-child {
      margin-right: 0 !important;
    }
  }
  .aligh-left {
    text-align: left;
  }

  #green-btn {
    background-color: $green-primary-n-c;
  }
 
  .add-all-to-cart {
    min-width: 380px;
    #pink-btn {
      // background-color: $red-light;
      background-color: $blue-bg;;
      border: none;
      color: #ffffff;
      padding: 10px 50px;
      font-size: 27px;
      line-height: 28px;
    }
    button {
      width: 100%;
    }

    .all-total {
      width: 100%;
      background-color: #f0f0f0;
      height: 107px;
      font-size: 70px;
      font-family: $Akzidenz;
      color: $red-light;
      padding: 0 25px;
    }
  }
  .total-consumption {
    width: 380px;
    h5 {
      font-size: 27px;
      line-height: 28px;
      white-space: nowrap;
      font-weight: bold;
    }
    .total-consumption-inner {
      width: 100%;
      font-family: $Akzidenz;
      color: #666666;

      .all-total {
        height: 107px;
        font-size: 60px;
      }
      .total-label {
        text-align: left;
        padding-left: 10px;
        font-size: 22px;
        line-height: 27px;
        height: 63px;
      }
    }
  }
  span {
    &.green {
      color: $green-primary-n-c;
    }
  }
  .nutrient-btn {
    height: 100px;
    min-width: 400px;
    font-size: 22px;
    line-height: 27px;
  }
  @media (max-width: 500px) {
    .nutrient-btn {
      height: 100px;
      min-width: 100%;
      font-size: 22px;
      line-height: 27px;
    }
  }
  .counter-slider-row {
    $counter-slider-row-top: 47px;
    padding-top: #{$counter-slider-row-top - 20px};
    .counter-slider {
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .spacing-10 {
    height: 10px;
  }
  .spacing-20 {
    height: 20px;
  }
}

#full-tilt-link {
  color: $green-primary-n-c;
}
.inner-text-anchor {
  text-decoration: none;
  color: $green-link;
  &:link {
    color: $green-link;
    text-decoration: none;
  }
  &:hover {
    color: $green-link;
    text-decoration: none;
  }

  &:visited {
    color: $green-link;
    text-decoration: none;
  }
  font-weight: bold;
}

@media (min-width: 1200px) {
  .block.category.event,
  .footer.content,
  .header.content,
  .navigation,
  .page-header .header.panel,
  .page-main {
    max-width: 1600px !important;
  }

  .page-wrapper > {
    .breadcrumbs,
    .page-bottom,
    .widget {
      max-width: 1600px !important;
    }
  }

  .top-container {
    max-width: 1600px !important;
  }
  .n-c-title-page {
    font-family: $latto;
    font-size: 33px;
    line-height: 30px;
    color: #424448;
    font-weight: bold;
  }
  .nutrient-separator-group {
    .n-s-first-item {
      padding-right: 14px;
      margin-right: 14px;
      border-right: 2px solid #424448;
      
    }
  }
  .footer-nc-container {
    font-family: $latto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 110px;
    .left-btn-nc {
      a #green-btn {
        font-size: 21px;
        line-height: 26.4px;
        width: 315px;
        padding: 0px !important;
        height: 70px;
        outline: none;
        min-width: auto;
        background-color: #86C240;
        sup {
          font-size: small;
        }
      }
    }
    .right-data-nc {
      .title-nc {
        text-align: right;
        color: #333333;
        font-size: 22px;
      }
      .logo-totals {
        @include flex-all;
        .logo-nc-footer {
          width: 134px;
          margin-right: 20px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .total-nc-footer {
          font-size: 45px;
          font-weight: 500;
          color: #76777A;
        }
        .descript-total-nc {
          width: 78px;
          font-size: 18px;
          font-weight: 500;
          line-height: 16px;
          color: #76777A;
          margin-left: 10px;
        }
      }
    }
  }
  .step-content.kit-room-layout.cart-popup[page="calculator"] {
    padding: 20px 20px 0px;
  }
}
