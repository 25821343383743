@import "./../../../../../../styles/global.scss";

.react-app-room-builder-nutrient-calculator-container {
  .card-wallet {
    flex: unset;
    min-width: 31.53%;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 27px;
    .nutrient-card-question-mark {
      padding: 10px 0 0 10px;
      svg {
        fill: $green-primary-n-c;
        width: 25px;
        height: 25px;
      }
    }
    .card-body {
      background-color: transparent;
      .nc-wallet-img {
        width: 220px;
        height: 155px;
        .card-img-top {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .nc-side-input-height {
        height: 47px;
      }
      .card-value {
        height: 70px;
      }
  
      .form-row {
        > .flex-all {
          width: 100%;
        }
        ;
        label {
          width: 41px;
          height: 100%;
          margin-right: 12px;
          font-size: 16px;
          white-space: nowrap;
          color: black;
        }
        .form-control {
          width: 67px;
          border-color: transparent;
          border-radius: 0;
          padding: 0;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          color: black;
          font-size: 24px!important;
          margin-right: 7px;
          input {
            width: 100%;
            height: 100%;
            font-size: 24px!important;
            padding: 1px;
            font-family: $latto;
            color: black;
            text-align: center;
            line-height: 30px;
          }
        }
        .price-unit-nc-wrapper {
          flex-direction: column;
          align-items: flex-start;
          width: 92px;
          overflow: hidden;
          & > div {
            text-overflow: ellipsis;
          }
        }
        .price-unit-nc-w-label {
          font-size: 16px;
          line-height: 20px;
          color: #54565A;
          font-family: $latto;
        }
        .price-unit-nc-w {
          font-size: 20px;
          line-height: 20px;
          color: $green-primary-n-c;
          font-family: $latto;
          font-weight: bold;
        }
        .col {
          flex-grow: unset;
          margin-bottom: 5px;
          flex-basis: initial!important;
        }
      }
      .result-value {
        color: $green-secondary;
        font-size: 48px;
        // font-size: 60px;
        font-family: $Akzidenz;
      }
      .lbs-needed {
        color: $green-secondary;
        font-size: 18px;
        font-weight: bold;
        font-family: $Akzidenz;
        margin-left: 5px;
      }
      .card-main {
        padding-top: 23px;
        background-color: #f0f0f0;
        padding-bottom: 8px;
        height: 243px;
      }
    }
    .v-btn {
      height: 100px;
      background-color: $green-primary-n-c;
      font-family: $Akzidenz;
      font-size: 18px;
      font-weight: bold;
      span {
        font-size: 35px;
        line-height: 35px;
      }
    }
    &.--card-blue {
      .nutrient-card-question-mark {
        svg {
          fill: $blue-bg;
        }
      }
      .card-body {
        .result-value {
          color: $primary-blue;
        }
        .lbs-needed {
          color: $primary-blue;
        }
        .card-main {
          background-color: #f0f0f0;
        }
      }
      .v-btn {
        background-color: $blue-bg;
      }
      .card-body .form-row .price-unit-nc-w {
        color: #1BABC8;
      }
    }
  }
}
