/*------------------------------------*\
  #GLOBAL COLORS
\*------------------------------------*/
$white: #ffffff;
$light-grey: #a7a8a9;
$grey: #76777a;
$dark-grey: #3c3c3c;
$idle-grey: #707070;
$highlight-dr-color: #d6d6d6;
$light-grey-sel: #dedede;
$light-grey2: #d3d3d3;
$very-light-grey: #e6e6e6;
$pale-yellow: #efefef;

$gold: #cc9f53;
$thin-yellow: #eaeaea;
$red-alert: #f75959;

$green-primary: #487629;
$green-primary-new: #61a60e;
$green-success: #39b54a;
$green-stroke: #487629;
$green-var-2: #61A61C;

$blue-constrast: #00abc8;
$dark-blue: #0475bb;

$background-color: #f8f8f8;
$title-color: #707070;
$light-grey-var-2: #D9D9D9; 
$input-color: black;
$input-background: white;
$font-color: #ffffff;
$grey: #aaaaaa;
$grey-666: #666666;
$secondary-button: #aaaaaa;

/**
  NUTRIENT CALCULATOR - start
*/
$green-primary-n-c: #85c240;
$green-secondary: #497628;
$green-link: #487629;

$blue-bg: #00abc7;
$primary-blue: #00555e;

$red-dark: #89494a;
$red-light: #ab7582;

$grey-input: #78787b;

/**
  NUTRIENT CALCULATOR - end
*/

/*------------------------------------*\
#GLOBAL FONTS
\*------------------------------------*/

$sf-compact-display: "Lato";
$latto: "Lato";
$Akzidenz: "Lato";
$helvetica-neue: "Lato";
$font-awesome-free: "Lato";
$helvetica-neue-bold: "Lato";
$Montserrat: "Lato";

/*------------------------------------*\
  #GLOBAL MARGINS
\*------------------------------------*/

$margin-bottom-title: 50px;
$margin-top-button: 40px;

$semibold: 600;

$header-height: 79px;

$room-builder-left-w: 395px;
$min-width-desk-: 1900px;

/*------------------------------------*\
  #GLOBAL MIXIN
\*------------------------------------*/

@mixin flex-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin overflow-elipsis-style {
  display: block; //overwrite styles like "display:flex" which prevent elipsis from working
  overflow: hidden;
  text-overflow: ellipsis;
}

//svgs
@mixin filled-check-circle($thisColor, $thisFill) {
  svg {
    path {
      stroke: $thisColor;
    }
    circle {
      &[data-name="arrow"] {
        stroke: $thisColor;
      }
      &[data-name="bg"] {
        fill: $thisFill;
      }
    }
  }
}
.frame-zoom-icon {
  &::after {
    content: url(./../assets/svg/frame-zoom.svg);
  }
}
.fullscreen-icon {
  &::after {
    content: url(./../assets/svg/fullscreen.svg);
  }
}
.refresh-icon {
  &::after {
    content: url(./../assets/svg/refresh.svg);
  }
}
.zoom-in-icon {
  &::after {
    content: url(./../assets/svg/zoom-in.svg);
  }
}
.zoom-out-icon {
  &::after {
    content: url(./../assets/svg/zoom-out.svg);
  }
}

//mixin sizes
@mixin one-sized {
  &.custom-input {
    width: 59px !important;
    text-align: center !important;
    min-width: unset !important;
    padding-left: 10px !important;
    padding-right: 5px !important;
  }
}

//mixin font styles
@mixin generic-latto-16 {
  font-family: $latto;
  font-size: 16px;
  line-height: 19px;
}
@mixin generic-montserrat-16 {
  font-family: $latto;
  font-size: 16px;
  line-height: 19px;
}

@mixin container-scroll-type-scrollbar {
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 27px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    outline: none;
    border-radius: 27px;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
}

@mixin generic-box-shadow {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
