@import "../../../styles/global.scss";

.custom-button {
  /* Clear default styling */
  color: white;
  border: 0px;

  /* Custom styling */
  background: $green-primary;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: center;
  font-family: $latto;
  &.disabled, //classname if you want a click action but needs to look disabled - preserve cursor pointer
  &:disabled {
    opacity: 0.65;
  }
  &.primary {
    background: $green-primary;
    outline: none;
    font-size: 16px;
    line-height: 19px;
    padding-top: 18px;
    padding-bottom: 18px;
    text-align: center;

    font-family: $latto;
    &.primary {
      background: $green-primary;
    }
  }
  &.primary-new {
    background: $green-primary-new;
    height: 70px;
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 700;
    line-height: normal;
  }
  &.secondary {
    background: $white;
    color: $green-primary;
    height: 70px;
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 700;
  }

  &.variant-1 {
    height: 45px;
    font-size: 16px;
    line-height: 19px;
    padding: 0 17px;
    @include flex-all;
    min-width: 124px;
    font-family: $latto;
  }
  &.forward {
    &::after {
      content: url(./../../../assets/svg/ctrl-right.svg);
      margin-left: 10px;
      width: 6.79px;
      height: 18.67px;
    }

    &.control {
      color: #54565a;
      background-color: #cccccc;
      font-weight: bold;
      height: 50px;
      min-width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;

      &.control-text {
        padding: 15px 15px;
      }

      &.control-sign {
        font-size: 40px;
        font-weight: normal;
      }
    }
  }
  &.control {
    background-color: #cccccc;
    color: #54565a;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $latto;
    font-size: 21px;
    font-weight: bold;

    &.control-sign {
      width: 50px;
      font-size: 50px;
      font-weight: lighter;
    }
    &.control-text {
      width: 127px;
    }
    &.control-question {
      font-family: $latto;
      width: 50.27px;
      font-size: 36px;
      font-weight: 700;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
    &.control-camera {
      width: 50px;
    }
  }

  &.question-button {
    margin: 0;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    font-size: 17px;
    color: #fff;
    background: #adadad;
    padding: 0;
  }
}
