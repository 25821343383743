@import "./../../../../styles/global.scss";
.wrapper-loading-3d-popup-rm {
  .modal-cstm-container {
    .modal-cstm-content {
      width: 569px;
      .wrapper-loading-3d-content-popup-rm {
        @include flex-start;
        flex-direction: column;
        width: 100%;
        padding: 0 58px;
        .modal-cstm-data {
          padding-bottom: 40px;
          padding-left: 0;
          padding-right: 0;
        }
        .save-room-rm-dropdown-container {
          .dropdown {
            .my-dropdown {
              padding-bottom: 14px;
              .my-dropdown-inner {
                button {
                }
              }
            }
          }
        }

        .popup-room-loading-content {
          width: 100%;
          padding-bottom: 35px;
          .slidecontainer-rm {
            width: 100%;
          }
          .slider {
            -webkit-appearance: none;
            width: 100%;
            height: 25px;
            background: #d3d3d3;
            outline: none;
            opacity: 0.7;
            -webkit-transition: 0.2s;
            transition: opacity 0.2s;
            &:hover {
              opacity: 1;
            }
            &::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 25px;
              height: 25px;
              background: #4caf50;
              cursor: pointer;
            }
            &::-moz-range-thumb {
              width: 25px;
              height: 25px;
              background: #4caf50;
              cursor: pointer;
            }
          }
        }
        .modal-buttons-save-room {
          width: 100%;
          height: 100px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          button {
            height: 70px;
            padding: 0px;
            text-align: center;
            font-size: 21px;
            line-height: 28px;
            font-weight: 700;
            border: none;
            border-radius: 0px;
            outline: none;
            text-transform: uppercase;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            background-color: #61a60e;
            color: #fff;
            width: 190px;
            &.btn-secondary {
              color: #487629;
              background-color: #fff;
              width: 175px;
              margin-right: 20px;
            }
          }
        }
        .estimation-rm-3d-item {
          font-family: $latto;
          font-size: 15px;
          line-height: 20px;
          color: $title-color;
          text-align: center;
          &.foot-note- {

          }
        }
        .estimation-rm-3d {
          padding: 15px 0 40px 0;
        }
      }
    }
  }
}
.plant-loader-pot {
  width: 453px;
  position: relative;
  .loader-plant-row {
    width: 100%;
    overflow-x: hidden;
    .loader-plant-row-content {
      width: 453px;
      justify-content: space-evenly;
      .plant-loader-item {
        
      }
    }
  }
}
